import Vue from 'vue'

Vue.component('step4', {
  props: [
    'user'
  ],
  inject: ['$validator'],
  data: function () {
    return {
      dataTitle: 'Quelques questions sur le pavillon et le Port d\'attache...',
      listPavillons: null,
      listDepts: null,
      listAttachePort: null,
      hasSameMouillage: true,
      listMouillagePort: null,
      validsValueslistPavillons: '',
      validsValueslistAttachePort: '',
      validsValueslistMouillagePort: '',
      validsValueslistDepts: '',
      errorLoadinglist: true
    }
  },
  methods: {
    getAttachePort() {
      this.$http.get('http://'+ document.domain +'/assurplaisance/ports/' + this.user.attachedepart, {responseType: 'json'}).then(response => {
        this.listAttachePort = response.data.map(e => e.port);
        this.validsValueslistAttachePort = response.data.map(e => e.port).join(',');
        this.errorLoadinglist = false;
      }, response => {
        this.errorLoadinglist = true;
      });
      return false;
    },
    getMouillagePort() {
      this.$http.get('http://'+ document.domain +'/assurplaisance/ports/' + this.user.mouillagedepart, {responseType: 'json'}).then(response => {
        this.listMouillagePort = response.data.map(e => e.port);
        this.validsValueslistMouillagePort = response.data.map(e => e.port).join(',');
        this.errorLoadinglist = false;
      }, response => {
        this.errorLoadinglist = true;
      });
      return false;
    }
  },
  mounted: function() {
    this.$http.get('http://'+ document.domain +'/assurplaisance/get_devis/nationalite_pavillons', {responseType: 'json'}).then(response => {
      this.listPavillons = response.data.map(e => e.pays);
      this.validsValueslistPavillons = response.data.map(e => e.pays).join(',');
      this.errorLoadinglist = false;
    }, response => {
      this.errorLoadinglist = true;
    });
    this.$http.get('http://'+ document.domain +'/assurplaisance/departements', {responseType: 'json'}).then(response => {
      this.listDepts = response.data.map(e => e);
      this.validsValueslistDepts = response.data.map(e => e.departement_code).join(',');
    }, response => {
      this.errorLoadinglist = true;
    });
  },

  computed: {},
  template: `<div class="tab col-12">
                <div class="row justify-content-center">                
                    <div class="col-12 col-sm-10 col-md-6">                    
                        <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                    </div>
                </div>   
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 mt-3 mb-1">                          
                        <p class="text-left"><strong>Le pavillon ...</strong></p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8">                     
                        <div class="form-group row">
                            <div class="col-12 col-lg-6">  
                                <select name="pavillon" id="pavillon" class="form-control form-control-lg" v-model="user.pavillon" data-vv-scope="step-4" data-vv-as="pavillon" data-vv-delay="500"
                                
                                                :class="{'is-invalid': errors.has('step-4.pavillon')}"
                                                v-validate="'required|included:' + validsValueslistPavillons">                                
                                            <option     v-for="pavillon in listPavillons"
                                                        :value="pavillon"
                                                        :selected="pavillon === 'FRANCE' ? 'selected' : ''">
                                                        {{pavillon}}
                                            </option>
                                    </select>
                                    
                                    <div v-show="errors.has('step-4.pavillon')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.pavillon') }}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 mt-3 mb-1">                          
                        <p class="text-left"><strong>Votre port d'attache ...</strong></p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8">
                        <div class="form-group row">
                            <div :class="user.attacheport !== 'FRANCE' ? 'col-12 col-md-6' : 'col-12 col-lg-4 mb-3 mb-lg-0'">
                                <select name="pays" id="pays" class="form-control form-control-lg" v-model="user.attacheport" data-vv-scope="step-4" data-vv-as="pays" data-vv-delay="500"
                                   :class="{'is-invalid': errors.has('step-4.pays')}"
                                                v-validate="'required|included:' + validsValueslistPavillons">                                
                                            <option     v-for="pays in listPavillons"
                                                        :value="pays"
                                                        :selected="pays === 'FRANCE' ? 'selected' : ''">
                                                        {{pays}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.pays')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.pays') }}
                                    </div>
                            </div>
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0" v-if="user.attacheport === 'FRANCE'">
                                <select name="dept" id="dept" class="form-control form-control-lg" v-model="user.attachedepart"
                                 data-vv-scope="step-4" data-vv-as="departement" data-vv-delay="500"
                                 @change="getAttachePort"
                                 :class="{'is-invalid': errors.has('step-4.dept')}"
                                                v-validate="'required|included:' + validsValueslistDepts"> 
                                            <option value="0" selected>Liste des départements</option>                                  
                                            <option     v-for="dept in listDepts"
                                                        :value="dept.departement_code">
                                                        {{dept.departement}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.dept')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.dept') }}
                                    </div>
                            </div>
                            <div class="col-12 col-lg-4" v-if="user.attacheport === 'FRANCE'">
                                <select name="port" id="port" class="form-control form-control-lg" data-vv-scope="step-4" data-vv-as="port" data-vv-delay="500"
                                   :class="{'is-invalid': errors.has('step-4.port')}"
                                                v-validate="''">
                                            <option value="0" selected>Liste des ports</option>                                  
                                            <option     v-for="port in listAttachePort"
                                                        :value="port">
                                                        {{port}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.port')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.port') }}
                                    </div>
                            </div>
            
                        </div>
                    </div>
                </div>
                 <div class="row justify-content-center mt-2 mb-2">                      
                      <div class="col-12 col-md-8">
                          <div class="form-group text-left">                             
                             <label class="control-label mr-5" for="mouillageok">Votre lieu de mouillage habituel est-il identique ?</label>
                             <div class="custom-control custom-radio custom-control-inline">
                                <input    type="radio"
                                          name="mouillage"
                                          id="mouillageok"
                                          :value="true"
                                          class="custom-control-input"
                                          v-model="hasSameMouillage"
                                          data-vv-scope="step-4">
                                <label class="custom-control-label" for="mouillageok">Oui</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                <input    type="radio"
                                          name="mouillage"
                                          id="mouillagenok"
                                          :value="false"                                         
                                          class="custom-control-input"
                                          v-model="hasSameMouillage">
                                <label class="custom-control-label" for="mouillagenok">Non</label>
                              </div>
                          </div>
                      </div> 
                </div>
                <div class="row justify-content-center" v-if="!hasSameMouillage">             
                      <div class="col-12 col-md-8">
                        <div class="form-group row">
                            <div :class="user.mouillageport === 'FRANCE' ? 'col-12 col-md-4' : 'col-12 col-lg-4 mb-3 mb-lg-0'">
                                <select name="mouillagepays" id="mouillagepays" class="form-control form-control-lg" v-model="user.mouillageport" data-vv-scope="step-4" data-vv-as="pays" data-vv-delay="500"
                                   :class="{'is-invalid': errors.has('step-4.mouillagepays')}"
                                                v-validate="'required|included:' + validsValueslistPavillons">
                                            <option     v-for="pays in listPavillons"
                                                  :value="pays"
                                                  :selected="pays === 'FRANCE' ? 'selected' : ''">
                                                  {{pays}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.mouillagepays')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.mouillagepays') }}
                                    </div>
                            </div>             
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0" v-if="user.mouillageport === 'FRANCE'">
                              <select name="mouillagedept" id="mouillagedept" class="form-control form-control-lg" data-vv-scope="step-4" data-vv-as="departement" data-vv-delay="500"
                                    @change="getMouillagePort"
                                 v-model="user.mouillagedepart"
                                 :class="{'is-invalid': errors.has('step-4.mouillagedept')}"
                                               v-validate="'required|included:' + validsValueslistDepts">
                                            <option value="0" selected>Liste des départements</option>                                  
                                            <option     v-for="dept in listDepts"
                                                      :value="dept.departement_code">
                                                        {{dept.departement}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.mouillagedept')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.mouillagedept') }}
                                    </div>
                                
                            </div>
                            <div class="col-12 col-lg-4" v-if="user.mouillageport === 'FRANCE'">
                                <select name="mouillageport" id="mouillageport" class="form-control form-control-lg" data-vv-scope="step-4" data-vv-as="port" data-vv-delay="500"
                                   :class="{'is-invalid': errors.has('step-4.mouillageport')}"
                                                v-validate="''">
                                            <option value="0" selected>Liste des ports</option>                                  
                                            <option     v-for="port in listMouillagePort"
                                                        :value="port">
                                                        {{port}}
                                            </option>
                                    </select>
                                    <div v-show="errors.has('step-4.mouillageport')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-4.mouillageport') }}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
});