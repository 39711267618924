import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {French} from 'flatpickr/dist/l10n/fr';

Vue.component('step12', {
    props: ['user'],
    data: function () {
        return {
            dataTitle: 'Ajouter des garanties complémentaires (suite)',
            titre: 'Nos meilleurs options pour vous garantir au mieux !',
            message: 'Notre contrat intègre les régates organisées par  votre club. Cependant souhaitez-vous étendre cette garantie ?',
            infos: 'Pour garantir votre bateau en location (hors pavillon NUC)',
            loa: 'Votre bateau a été acheté en LOA nous vous conseillons par conséquent l’option suivante',
            remorque: 'Si vous avez une remorque veuillez choisir une des options ci-dessous',
            ffvoile: false,
            isaf: false,
            licence: null,
            showHelp: false,
            locationNoSkipper: false,
            locationDetourn: false,
            locationSkipper: false,
            locationQuai: false,
            helpLocVisible: false,
            perteFi: false,
            helpPerteVisible: false,
            remorquePlus: false,
            remorqueMoins: false,
            responsabilite: null,
        }
    },
    components: {
        flatPickr
    },
    methods: {
        setHelpPerteVisible: function() {
            this.helpPerteVisible = !this.helpPerteVisible;
        },
    },
    computed: {
        getCurrentY: function() {
            let d = new Date();
            return parseInt(d.getFullYear());
        },
    },
    inject: ['$validator'],
    template: `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <h4 id="titre-tab" class="mt-3 mb-1" v-html="dataTitle"></h4>                          
                            <p class="text-center"><strong v-html="loa"></strong></p>
                        </div>
                    </div>
                    <div class="row justify-content-center" v-if="[2].includes(parseInt(user.finance))">
                        <div class="col-12 col-lg-3">
                            <div class="form-group row">
                                 <div class="col-12">
                                    <!-- TODO : Faire le style des checkbox -->
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="perteFi"
                                                id="perteFi"
                                                v-model="perteFi"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-12"
                                                :class="{'is-invalid': errors.has('step-12.perteFi')}"
                                                v-validate="''">
                                        <label for="perteFi" class="lh-2 custom-control-label custom-control-stack-label">
                                            Perte financière<br><small>(Bateau acheté en LOA)</small>                                                
                                        </label><br>
                                        <small role="button" @click="showHelp = true"><i class="fa-2x fa fa-question-circle"></i></small>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <transition name="bounce">
                    <!-- Modal content -->
                    <div class="custom-modal" v-if="showHelp">
                    <div class="custom-modal-content">
                      <div class="modal-top">
                        <div class="container-fluid"> 
                        <div class="row justify-content-end">
                            <div class="col-12">
                              <span @click="showHelp = false" class="close">&times;</span>
                            </div>
                        </div>
                        </div>                        
                      </div>
                      <div class="modal-middle"> 
                        <div class="container-fluid">                     
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="media mt-5 mb-3">
                                    <img class="rounded-circle mr-3" width="80"
                                         src="/templates/medias/templates/amtm/images/equipe/couleur/Laure-allochon.png"
                                         alt="">
                                    <div class="media-body text-fourth text-left pl-5"
                                         style="position: relative;">
                                        <div class="vertical-caret"
                                             style="position: absolute;top: 0px;height: 100%;left: 0px;width: 1px;background-color: #f37323;">
                                            <div class="caret"
                                                 style="position: absolute;transform: rotate(-135deg) translateY(50%);top: 50%;height: 20px;left: -16px;width: 20px;background-color: #eff0f4;border-bottom: 1px solid #f37323;border-left: 1px solid #f37323;"></div>
                                        </div>
                                        <p><b>Votre bateau est financé par crédit bail ou par location avec option d’achat.</b></p>        
                                        <p>En cas de perte totale ou vol total du bateau assuré suite à un événement garanti :</p> 
                                        <ul>
                                        <li>si votre bateau est financé par crédit, nous réglons à l’organisme créancier la différence entre la somme restant due et l’indemnité qui vous est versée au titre de la garantie dommages, </li>
                                        <li>si votre bateau est financé par crédit bail ou par location avec option d’achat, nous réglons à l’organisme créancier la différence entre les loyers restant dus et l’indemnité qui vous est versée au titre de la garantie. </li>
                                         <li>nous réglons également à l’organisme créancier, l’indemnité de remboursement anticipé, que celui-ci peut vous réclamer.</li> 
                                        </ul>                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </transition>
                    
                </div>`

});