import Vue from 'vue'
import vSelect from 'vue-select'
Vue.component('step5', {
    props: [
      'user'
    ],
    components: {
      vSelect
    },
    inject: ['$validator'],
    data: function() {
        return {
          currentOrganismes: '',
          dataTitle: 'Comment avez-vous financé votre bateau ?',
          listBanques: [
            'Commencez à taper votre texte ...'
          ],
            // validsValueslistBanques: null,
            errorLoadinglist: true
        }
    },
  methods: {
    seturrentOrganismes(val) {
      if(val) {
        this.currentOrganismes = val;
      } else {
        this.currentAssoc = ''
      }
      return false;
    }
  },

  mounted: function() {
    this.$http.get('http://'+ document.domain +'/assurplaisance/get_devis/banques', {responseType: 'json'}).then(response => {
      this.listBanques = response.data.map(e => e.banque);
      // this.validsValueslistBanques = response.data.map(e => e.banque).join(',');
      this.errorLoadinglist = false;
    }, response => {
      this.errorLoadinglist = true;
    });
  },

    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8">
                            <p class="text-primary text-uppercase fs-3"><b>- Financement par -</b></p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-xl-5">
                            <div class="form-group row">
                                <div class="col-4">
                                     <div class="custom-control-stack custom-radio">                                     
                                        <input  type="radio"
                                                name="finance"
                                                id="creditfinance"
                                                :value="1"
                                                class="custom-control-input"
                                                v-model="user.finance"        
                                                data-vv-as="financement"                                    
                                                data-vv-scope="step-5"
                                                :class="{'is-invalid': errors.has('step-5.finance')}"
                                                v-validate="'required|included:1,2,3'">
                                        <label class="custom-control-label custom-control-stack-label" for="creditfinance">
                                            Crédit
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="custom-control-stack custom-radio">
                                          <input  type="radio"
                                                    name="finance"
                                                    id="leasingfinance"
                                                    :value="2"
                                                    class="custom-control-input"
                                                    v-model="user.finance"                                          
                                                    data-vv-scope="step-5"
                                                    :class="{'is-invalid': errors.has('step-5.finance')}">
                                           <label class="custom-control-label custom-control-stack-label" for="leasingfinance">
                                                Leasing (LOA)
                                           </label>
                                    </div>
                                </div>                                
                                <div class="col-4">
                                    <div class="custom-control-stack custom-radio">
                                          <input  type="radio"
                                                name="finance"
                                                id="comptantfinance"
                                                :value="3"
                                                class="custom-control-input"
                                                v-model="user.finance"                                       
                                                data-vv-scope="step-5"
                                                :class="{'is-invalid': errors.has('step-5.finance')}">
                                          <label class="custom-control-label custom-control-stack-label" for="comptantfinance">
                                              Comptant
                                          </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="row justify-content-center" v-show="errors.has('step-5.finance')">
                        <div class="col-8">
                            <div class="form-group row">
                                <div class="col-12">
                                    <input  type="hidden"
                                                class="form-control"
                                                :class="{'is-invalid': errors.has('step-5.finance')}">                            
                                    <div class="invalid-feedback">
                                         {{ errors.first('step-5.finance') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" v-if="user.finance == 1 || user.finance == 2">                    
                        <div class="col-12 col-xl-4">
                            <div class="form-group row">
                                <div class="col-12 mt-3">    
                                     <v-select
                                        label="libelle"
                                        placeholder="Liste des organismes"
                                        input-id="organismefinance"
                                        taggable
                                        :options="listBanques"
                                        :class="{'is-invalid': errors.has('step-5.organismefinance')}"
                                        value.sync="currentOrganismes"   
                                        v-on:input="seturrentOrganismes">
                                        <span slot="no-options">
                                          Aucun organisme ne correspond ...
                                        </span>
                                    </v-select>
                                    <input class="form-control form-control-lg" type="hidden" v-model="currentOrganismes" name="organismefinance"
                                    data-vv-scope="step-5"
                                    data-vv-as="organisme de financement"
                                    data-vv-delay="500"
                                    :class="{'is-invalid': errors.has('step-5.organismefinance')}"
                                     v-validate="'required'"/>
                                    <div v-show="errors.has('step-5.organismefinance')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-5.organismefinance') }}
                                    </div>  
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>`

});
// proposition de choix autre