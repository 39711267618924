import Vue from 'vue'

Vue.component('paginate', {
    props: [
        'dataNb',
        'dataCurrent'
    ],
    template:   `<div class="tab">
                    <span v-for="k in dataNb" :class="dataCurrent === k ? ' active' : ''" class="step"></span>
                </div>`

});