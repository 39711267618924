// ** JS **
import $ from 'jquery'
import 'bootstrap'
import 'slick-carousel'
import AOS from 'aos'
import 'jquery-validation'
import './../../../node_modules/jquery-validation/dist/localization/messages_fr'
import Masonry from 'masonry-layout'
import './jquery.numscroller-1.0'
import Vue from 'vue'
import VueResource from 'vue-resource';
import VeeValidate, {Validator} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr';
// ** CSS **
import '../scss/main.scss'
import '../../../node_modules/aos/dist/aos.css'
// ** IMAGES **
import './../images/bgAuth.jpg'
// ** LOGO SITE **
import './../images/logo/logo-amtm.png'
// ** SLIDER HOME **
import '../images/header/slider1.jpg'
import '../images/header/slider2.jpg'
import '../images/header/slider3.jpg'
import '../images/header/slider4.jpg'
// ** LIENS FOOTER **
import '../images/footer/bg-block-footer_1.jpg'
import '../images/footer/bg-block-footer_2.jpg'
import '../images/footer/bg-block-footer_3.jpg'
// ** TOUTES PAGES **
import '../images/content/boat.png'
// ** NOTRE HISTOIRE **
import '../images/header/histoire.jpg'
import '../images/content/histoire-thumb.jpg'
import '../images/content/histoire-top.jpg'
import '../images/content/histoire-bottom.jpg'
import '../images/content/mock-pres-ass-mader.png'
// ** NOS VALEURS **
import '../images/header/valeurs.jpg'
import '../images/content/valeurs-thumb.jpg'
import '../images/content/valeurs-thumb-1.jpg'
import '../images/content/valeurs-top.jpg'
import '../images/content/valeurs-bottom.jpg'
// ** PORTRAI **
import '../images/header/nosequipes.jpg'
import './../images/equipe/couleur/Agathe-mader.png'
import './../images/equipe/couleur/Celine-Blandin.png'
import './../images/equipe/couleur/Christophe-mader.png'
import './../images/equipe/couleur/Guillaume-mader.png'
import './../images/equipe/couleur/Laure-allochon.png'
import './../images/equipe/couleur/Louis-mader.png'
import './../images/equipe/couleur/Patrick-Legonidec.png'
import './../images/equipe/couleur/Patricia-Marchasson.png'
import './../images/equipe/couleur/Maryline-lebrun.png'
import './../images/notretravail/couleur/01.jpg'
import './../images/notretravail/couleur/02.jpg'
import './../images/notretravail/couleur/03.jpg'
import './../images/notretravail/couleur/04.jpg'
import './../images/notretravail/couleur/05.jpg'
import './../images/notretravail/couleur/06.jpg'
import './../images/notretravail/couleur/07.jpg'
import './../images/notretravail/couleur/08.jpg'
import './../images/notretravail/couleur/09.jpg'
import './../images/notretravail/couleur/10.jpg'
import './../images/notretravail/couleur/11.jpg'
import './../images/notretravail/couleur/12.jpg'
import './../images/notretravail/couleur/13.jpg'
import './../images/notretravail/couleur/14.jpg'
import './../images/notretravail/couleur/15.jpg'
import './../images/notretravail/couleur/16.jpg'
import './../images/notretravail/couleur/17.jpg'
// ** GRAIN **
import '../images/header/grain.jpg'
import '../images/logo/esprit.png'
import '../images/logo/surfrider_foundation_europe_logo.png'
import '../images/content/grain-thumb.jpg'
import '../images/content/grain-top.jpg'
import '../images/content/grain-bottom.jpg'
// ** AMBASSADEURS **
import '../images/header/ambassadeurs.jpg'
import '../images/ambassadeurs/patrick-rieupeyrout.jpg'
import '../images/ambassadeurs/michel-desjoyeaux.jpg'
import '../images/ambassadeurs/isabelle-autissier.jpg'
import '../images/ambassadeurs/philippe-facque.jpg'
import '../images/ambassadeurs/jp-couteleau.jpg'
import '../images/ambassadeurs/eric-bruneel.jpg'
import '../images/ambassadeurs/francois-frey.jpg'
import '../images/ambassadeurs/antoine-albeau.jpg'
import '../images/ambassadeurs/charline-picon.jpg'
import '../images/ambassadeurs/fabien-pianazz.jpg'
import '../images/ambassadeurs/alain-garcia.jpg'
import '../images/ambassadeurs/yann-dalibot.jpg'
// ** DEVIS **
import '../images/zone-de-nav-europe.jpg'
// ** FORMULES **
import '../images/header/garanties.jpg'
import '../images/logo/yatch.png'
import '../images/logo/safe.png'
import '../images/logo/protect.png'
// ** PORTUAIRES **
import '../images/header/assurport.jpg'
import '../images/content/port-thumb.jpg'
import '../images/content/port-thumb-2.jpg'
import '../images/content/port-top.jpg'
import '../images/content/port-top-2.jpg'
import '../images/content/port-bottom.jpg'
import '../images/content/mock-pres-ass-mader-ports.png'
// ** MARCHANDISES **
import '../images/header/marchandises.jpg'
// ** PRO **
import '../images/header/espacepro2.jpg'
import '../images/content/pro-thumb.jpg'
import '../images/content/pro-top.jpg'
import '../images/content/pro-bottom.jpg'
// ** INFORMATIONS **
import '../images/header/infomartions.jpg'
// ** CONTACT **
import '../images/header/contact.jpg'
// ** LOGOS REFS ET / OU PARTENAIRES **
import './../images/logo/references/color/amel.png'
import './../images/logo/references/color/appa.png'
import './../images/logo/references/color/assoc-plaisanciers.png'
import './../images/logo/references/color/atlantic-cluster.png'
import './../images/logo/references/color/bavaria.png'
import './../images/logo/references/color/cdk.png'
import './../images/logo/references/color/ffpp.png'
import './../images/logo/references/color/ffv.png'
import './../images/logo/references/color/fin.png'
import './../images/logo/references/color/fnp.png'
import './../images/logo/references/color/harken.png'
import './../images/logo/references/color/herve.png'
import './../images/logo/references/color/meragitee.png'
import './../images/logo/references/color/neel.png'
import './../images/logo/references/color/sooruz.png'
import './../images/logo/references/color/srr.png'
import './../images/logo/references/color/surfrider.png'
import './../images/logo/references/color/unan.png'
import './../images/logo/references/color/velox.png'
// -----
import './../images/logo/references/nb/amel.png'
import './../images/logo/references/nb/appa.png'
import './../images/logo/references/nb/assoc-plaisanciers.png'
import './../images/logo/references/nb/atlantic-cluster.png'
import './../images/logo/references/nb/bavaria.png'
import './../images/logo/references/nb/cdk.png'
import './../images/logo/references/nb/ffpp.png'
import './../images/logo/references/nb/ffv.png'
import './../images/logo/references/nb/fin.png'
import './../images/logo/references/nb/fnp.png'
import './../images/logo/references/nb/harken.png'
import './../images/logo/references/nb/herve.png'
import './../images/logo/references/nb/meragitee.png'
import './../images/logo/references/nb/neel.png'
import './../images/logo/references/nb/sooruz.png'
import './../images/logo/references/nb/srr.png'
import './../images/logo/references/nb/surfrider.png'
import './../images/logo/references/nb/unan.png'
import './../images/logo/references/nb/velox.png'
// ** DOCUMENTS **
import '../pdfs/amtm/dossier-presentation-nautisme-mader-amtm.pdf'
import '../pdfs/amtm/dossier-presentation-mader-assurances.pdf'
import '../pdfs/garanties/guide-de-survie-sinsitre-4-etapes.pdf'
// ** VUE **
import "./steps/step1";
import "./steps/step2";
import "./steps/step3";
import "./steps/step4";
import "./steps/step5";
import "./steps/step6";
import "./steps/step7";
import "./steps/step8";
import "./steps/step9";
import "./steps/step10";
import "./steps/step11";
import "./steps/step12";
import "./steps/step13";
import "./steps/step14";
import "./steps/paginate";

// ** SCRIPTS **
Vue.use(VeeValidate, {
  fieldsBagName: 'fields',
  errorBagName: 'errors',
  inject: false
});
Validator.localize('fr', fr);
Vue.use(VueResource);
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  value = value.toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('slugify', function (value) {
  return value.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
});

window.AOS = AOS

let jQuery = $
window.jQuery = $
window.$ = $
window.Vue = Vue;
window.Masonry = Masonry

$(document).ready(function () {
  $(".img-hover").hover(
    function () {
      $(this).addClass('border-primary')
      let src = $(this).find("img").attr("src")
      $(this).find("img").attr("src", src.replace("nb", "color"))
    }, function () {
      $(this).removeClass('border-primary')
      let src = $(this).find("img").attr("src")
      $(this).find("img").attr("src", src.replace("color", "nb"))
    }
  );

  $('main').css('marginTop', '-' + $('[aria-label="breadcrumb"]').height() + 'px')

  if ($('#home-slider').length) {
    $('#home-slider').slick({
      nextArrow: '<div class="container arrows"><div class="arrow after"><i class="slick-icon icon-next"></i></div></div>',
      prevArrow: '<div class="container arrows"><div class="arrow before"><i class="slick-icon icon-before"></i></div></div>'
    })
  }

  if (document.getElementById('nav-toggler')) {
    document.getElementById('nav-toggler').onclick = function () {
      document.getElementById('sidenav').classList.toggle('open')
      document.getElementById('main-nav').classList.toggle('sidenav-opened')
      this.classList.toggle('open')
      document.body.classList.toggle('nav-opened')
    }
  }
})

if (document.getElementById('app') !== null) {
  var app = new Vue({
    el: '#app',
    data: {
      submitUrl: '',
      currentStep: 0,
      nbSteps: 14,
      user: {
        prenom: '',
        nom: '',
        email: '',
        boat: 0,
        listConstructeurs: [
          'Commencez à taper votre texte ...'
        ],
        currentConstructor: '',
        pavillon: 'FRANCE',
        attacheport: 'FRANCE',
        attachedepart: '0',
        mouillageport: 'FRANCE',
        mouillagedepart: '0',
        longBateau: '',
        isVoilier: null,
        finance: 0,
        dejaassur: false
      },
    },
    computed: {
      activ() {
        return {
          'step1': true,
          'step2': true,
          'step3': ![7].includes(this.user.boat),
          'step4': ![7].includes(this.user.boat),
          'step5': ![7].includes(this.user.boat),
          'step6': ![7].includes(this.user.boat),
          'step7': ![7].includes(this.user.boat),
          'step8': ![7].includes(this.user.boat),
          'step9': ![7].includes(this.user.boat) && this.user.dejaassur,
          'step10': ![7].includes(this.user.boat) && [1, 2, 3].includes(this.user.boat),
          'step11': ![7].includes(this.user.boat),
          'step12': ![7].includes(this.user.boat) && [2].includes(this.user.finance),
          'step13': ![7].includes(this.user.boat) && parseInt(this.user.longBateau) < 8,
          'step14': true
        }
      },
    },
    methods: {
      changeStep: function (step) {
        if (step == 1) {
          this.$validator.validateAll('step-' + this.currentStep).then((result) => {
            if (result) {
              if (this.currentStep === this.nbSteps) {
                document.getElementById('formulaire-devis').submit()
              } else {
                let m = Object.keys(this.activ)[this.currentStep]
                if (this.activ[m]) {
                  this.currentStep++;
                } else {
                  let temp = this.currentStep
                  do {
                    temp++
                  } while (!this.activ[Object.keys(this.activ)[temp]]);
                  this.currentStep = temp + 1
                }
              }
            }
          });
        }
        else {
          let m = Object.keys(this.activ)[this.currentStep - 2]
          this.currentStep--;
          if (this.activ[m] === false) {
            this.changeStep(-1)
          }
        }
        return false;
      }
    }
  })
}
