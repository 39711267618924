import Vue from 'vue'

Vue.component('step2', {
    props: [
      'user'
    ],
    computed: {
        dataTitle() {
            return 'Heureuse de vous aider '+this.user.prenom+' !<br>Merci de cocher votre type de bateau.';
        },
        showMessageJetSki() {
            return false; // this.user.boat === 7;
        }
    },
    methods: {
      handleClick() {
        var vm = this
        fetch('http://'+ document.domain +'/assurplaisance/constructeurs/' + this.user.boat).then(response => {
          response.json().then(function(json) {
            vm.user.listConstructeurs = json
            vm.$root.$refs.step3.$children[0].clearSelection()
            vm.user.currentConstructor  = ''
          }).catch(err => {
            console.log(err)
          });
        });
        return false;
      }
    },
    inject: ['$validator'],
    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>  
                    </div>  
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
                            <div class="form-group row">
                                <div class="col-12">                                    
                                    <p class="mb-3"><i class="icon-boat-voile1 fs-6 fs-lg-14"></i></p>
                                    <p class="mb-3 text-primary text-uppercase fs-3"><b>- Bateaux à voile -</b></p>
                                    <div class="mb-3 row justify-content-center">
                                        <div class="custom-control-stack custom-radio">
                                            <input type="radio"
                                                    name="type"
                                                    id="type1"
                                                    :value="1"  
                                                    class="custom-control-input"    
                                                    v-on:change="handleClick"  
                                                    v-model="user.boat"   
                                                    data-vv-as="type de bateau"           
                                                    data-vv-scope="step-2"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"
                                                    v-validate="'required|included:1,2,3,4,5,6,7'">
                                            <label for="type1" class="custom-control-label custom-control-stack-label">
                                                Monocoque      
                                            </label>
                                        </div>
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type2"
                                                    :value="2"                                                                        
                                                    class="custom-control-input"    
                                                    v-on:change="handleClick"     
                                                    v-model="user.boat"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type2" class="custom-control-label custom-control-stack-label">
                                                Catamaran 
                                            </label>
                                        </div>                                         
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type3"
                                                    :value="3"    
                                                    class="custom-control-input"    
                                                    v-on:change="handleClick"     
                                                    v-model="user.boat"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type3" class="custom-control-label custom-control-stack-label">
                                                Trimaran      
                                            </label>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-5">
                            <div class="form-group row">
                                <div class="col-12">
                                    <p class="mb-3"><i class="icon-boat-motor-2 fs-6 fs-lg-14"></i></p>
                                    <p class="mb-3 text-primary text-uppercase fs-3"><b>- Bateaux à moteur -</b></p>
                                    <div class="mb-3 row justify-content-center">                                                                              
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type4"
                                                    :value="4"              
                                                    class="custom-control-input"  
                                                    v-on:change="handleClick"       
                                                    v-model="user.boat" 
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type4" class="custom-control-label custom-control-stack-label">
                                                Coque rigide      
                                            </label>
                                        </div>                                                                               
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type5"
                                                    :value="5"           
                                                    class="custom-control-input"      
                                                    v-on:change="handleClick"   
                                                    v-model="user.boat"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type5" class="custom-control-label custom-control-stack-label">
                                                Semi-rigide
                                            </label>
                                        </div>                                                                       
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type6"
                                                    :value="6"         
                                                    class="custom-control-input"  
                                                    v-on:change="handleClick"    
                                                    v-model="user.boat"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type6" class="custom-control-label custom-control-stack-label">
                                                Pneumatique
                                            </label>
                                        </div>                                                                      
                                        <div class="custom-control-stack custom-radio">
                                           <input  type="radio"
                                                    name="type"
                                                    id="type7"
                                                    :value="7"         
                                                    class="custom-control-input"  
                                                    v-on:change="handleClick"      
                                                    v-model="user.boat"
                                                    :class="{'is-invalid': errors.has('step-2.type')}"> 
                                            <label for="type7" class="custom-control-label custom-control-stack-label">
                                                Jet ski
                                            </label>
                                        </div>  
                                    </div>                                   
                                </div>
                            </div>
                        </div>   
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                            <div class="form-group">
                                <input  type="hidden"
                                        class="form-control"
                                        :class="{'is-invalid': errors.has('step-2.type')}">                            
                                <div v-show="errors.has('step-2.type')"
                                     class="invalid-feedback">
                                     {{ errors.first('step-2.type') }}
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div v-show="showMessageJetSki" class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4">
                            <p><i class="text-primary fa fa-warning"></i> Ce produit n’est pas délégué merci de bien vouloir contacter AMTM au 05 46 41 20 22</p> 
                        </div>
                    </div>
                </div>`

});

