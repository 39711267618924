import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import vSelect from 'vue-select'
import {French} from 'flatpickr/dist/l10n/fr';

Vue.component('step13', {
    data: function () {
        return {
            dataTitle: 'Ajouter des garanties complémentaires (suite)',
            titre: 'Nos meilleurs options pour vous garantir au mieux !',
            message: 'Notre contrat intègre les régates organisées par  votre club. Cependant souhaitez-vous étendre cette garantie ?',
            infos: 'Pour garantir votre bateau en location (hors pavillon NUC)',
            loa: 'Votre bateau a été acheté en LOA nous vous conseillons par conséquent l’option suivante',
            remorque: 'Si vous avez une remorque veuillez choisir une des options ci-dessous',
            showHelp: false,

            listMarques: [],
            currentMarque: '',

            maxYear: new Date().getFullYear() + 1,
            minYear: 1950,

            remorquePlus: false,
            remorqueMoins: false,
            responsabilite: '',
        }
    },
    components: {
        flatPickr,
      vSelect
    },
    methods: {
      setcurrentMarque(val) {
        if(val) {
          this.currentMarque = val;
        } else {
          this.currentMarque = ''
        }
        return false;
      },

        setHelpPerteVisible: function() {
            this.helpPerteVisible = !this.helpPerteVisible;
        },
    },
    computed: {
        setIsLoa: function () {
            return [2].includes(parseInt(this.$root.$refs.step5.$data.finance))
        },
        setIs8m: function () {
            return true; // [2].indexOf(this.$root.$refs.step5.$data.finance) != -1
        },
        getCurrentY: function() {
            let d = new Date();
            return parseInt(d.getFullYear());
        },
    },
    mounted() {
      this.$http.get('http://'+ document.domain +'/assurplaisance/remorques', {responseType: 'json'}).then(response => {
        this.listMarques = response.data.map(e => e.marque);
        // this.validsValueslistBanques = response.data.map(e => e.banque).join(',');
        this.errorLoadinglist = false;
      }, response => {
        this.errorLoadinglist = true;
      });

    },
    inject: ['$validator'],
    template: `
    <div class="tab col-12">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 mb-3">
                <h4 id="titre-tab" class="mt-3 mb-1" v-html="dataTitle"></h4>
                <p class="text-center"><strong v-html="remorque"></strong></p>
            </div>
        </div>
        <div class="row justify-content-center" v-if="setIs8m">            
            <div class="col-12 col-lg-6">
                <div class="form-group row">
                    <div class="col-12">
                        <p class="mb-3 text-primary text-uppercase fs-3"><b>- Assurance remorque -</b></p>
                    </div>
                    <div class="col-6">
                        <div class="custom-control-stack custom-checkbox">
                            <input type="checkbox" name="remorqueMoins" id="remorqueMoins" v-model="remorqueMoins" :value="true" class="custom-control-input" data-vv-scope="step-13" :class="{'is-invalid': errors.has('step-13.remorqueMoins')}" v-validate="{ is: remorqueMoins && remorquePlus }">
                            <label for="remorqueMoins" class="lh-2 custom-control-label custom-control-stack-label">
                                PTAC - 750Kg
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="custom-control-stack custom-checkbox">
                            <input type="checkbox" name="remorquePlus" id="remorquePlus" :value="true" v-model="remorquePlus" class="custom-control-input" data-vv-scope="step-13" :class="{'is-invalid': errors.has('step-13.remorquePlus')}" v-validate="{ is: remorqueMoins && remorquePlus }">
                            <label for="remorquePlus" class="lh-2 custom-control-label custom-control-stack-label">
                                PTAC + 750Kg
                            </label><br>
                            <small role="button" @click="showHelp = true"><i class="fa fa-2x fa-question-circle"></i></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="bounce">
            <!-- Modal content -->
            <div class="custom-modal" v-if="showHelp">
                <div class="custom-modal-content">
                    <div class="modal-top">
                        <div class="container-fluid">
                            <div class="row justify-content-end">
                                <div class="col-12">
                                    <span @click="showHelp = false" class="close">&times;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-middle">
                        <div class="container-fluid">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="media mt-5 mb-3">
                                        <img class="rounded-circle mr-3" width="80" src="/templates/medias/templates/amtm/images//equipe/couleur/Laure-allochon.png" alt="">
                                        <div class="media-body text-fourth text-left pl-5" style="position: relative;">
                                            <div class="vertical-caret" style="position: absolute;top: 0px;height: 100%;left: 0px;width: 1px;background-color: #f37323;">
                                                <div class="caret" style="position: absolute;transform: rotate(-135deg) translateY(50%);top: 50%;height: 20px;left: -16px;width: 20px;background-color: #eff0f4;border-bottom: 1px solid #f37323;border-left: 1px solid #f37323;"></div>
                                            </div>
                                            <p>Nous transmettre par e-mail une copie de la carte grise de votre véhicule.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div v-if="remorqueMoins && remorquePlus">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-5">
                    <div class="form-group row">
                        <div class="col-12">
                            <input type="hidden" class="form-control form-control-lg is-invalid" />
                            <div class="invalid-feedback">
                                Impossible de cumuler ces deux options.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="remorquePlus || remorqueMoins">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8">
                        <div class="form-group row">
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <v-select placeholder="Marque de la remorque" input-id="marqueRemorque" :options="listMarques" :class="{'is-invalid': errors.has('step-13.marqueRemorque')}" value.sync="currentMarque" v-on:input="setcurrentMarque">
                                    <span slot="no-options">
                                        Sélectionnez une marque ...
                                    </span>
                                </v-select>
                                <input class="form-control form-control-lg" type="hidden" v-model="currentMarque" name="marqueRemorque" data-vv-scope="step-13" data-vv-as="marque de la remorque" data-vv-delay="500" :class="{'is-invalid': errors.has('step-13.marqueRemorque')}" v-validate="'required'" />
                                <div v-show="errors.has('step-13.marqueRemorque')" class="invalid-feedback">
                                    {{ errors.first('step-13.marqueRemorque') }}
                                </div>
                                <!--
                                            <input
                                                    type="text"
                                                    name="marqueRemorque"
                                                    id="marqueRemorque"
                                                    placeholder="Marque"
                                                    class="form-control form-control-lg"
                                                    data-vv-scope="step-13"
                                                    data-vv-as="marque"
                                                    data-vv-delay="500"
                                                    :class="{'is-invalid': errors.has('step-13.marqueRemorque')}"
                                                    v-validate="'decimal'">                                               
                                            <div v-show="errors.has('step-13.marqueRemorque')"
                                                 class="invalid-feedback">
                                                 {{ errors.first('step-13.marqueRemorque') }}
                                            </div>
                                            -->
                            </div>
                            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                                <input type="text" name="anneeRemorque" id="anneeRemorque" placeholder="Année" class="form-control form-control-lg" data-vv-scope="step-13" data-vv-as="année" data-vv-delay="500" :class="{'is-invalid': errors.has('step-13.anneeRemorque')}" v-validate="'digits:4|max_value:'+ maxYear +'|min_value:'+ minYear">
                                <div v-show="errors.has('step-13.anneeRemorque')" class="invalid-feedback">
                                    {{ errors.first('step-13.anneeRemorque') }}
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="input-group"> 
                                  <input type="text" name="prixRemorque" id="prixRemorque" placeholder="Valeur" class="form-control form-control-lg" data-vv-scope="step-13" data-vv-as="valeur" data-vv-delay="500" :class="{'is-invalid': errors.has('step-13.prixRemorque')}" v-validate="'decimal'">
                                  <div class="input-group-append">
                                      <span class="input-group-text"><small>(en €)</small></span>
                                  </div>
                                  <div v-show="errors.has('step-13.prixRemorque')" class="invalid-feedback">
                                      {{ errors.first('step-13.prixRemorque') }}
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8 mt-3 mb-1">
                        <p class="text-center"><strong>Choisissez votre niveau de Garantie</strong></p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-5">
                        <div class="form-group row">
                            <div class="col-6">
                                <div class="custom-control-stack custom-radio">
                                    <input type="radio" name="responsabilite" id="responsabiliteCivile" value="1" v-model="responsabilite" class="custom-control-input" data-vv-as="responsabilite" data-vv-scope="step-13" :class="{'is-invalid': errors.has('step-13.responsabilite')}" v-validate="'required|included:1,2'">
                                    <label for="responsabiliteCivile" class="custom-control-label custom-control-stack-label">
                                        <p class="lh-2 mt-2">
                                            Responsabilité civile<br><small>uniquement</small></p>
    
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="custom-control-stack custom-radio">
                                    <input type="radio" name="responsabilite" id="responsabiliteVol" v-model="responsabilite" value="2" class="custom-control-input" :class="{'is-invalid': errors.has('step-13.responsabilite')}">
                                    <label for="responsabiliteVol" class="custom-control-label custom-control-stack-label">
                                        <p class="lh-2 mt-2">
                                            Responsabilité civile<br><small>+ dommages et vol</small></p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center" v-if="errors.has('step-13.responsabilite')">
                    <div class="col-12 col-lg-5">
                        <div class="form-group row">
                            <div class="col-12">
                                <input type="hidden" class="form-control form-control-lg is-invalid" />
                                <div class="invalid-feedback">
                                    Merci d'indiquer une garantie.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `

});