import Vue from 'vue'

Vue.component('step7', {
    inject: ['$validator'],
    data: function() {
        return {
            zoneue: true,
            zoneflu: false,
            zoneant: false,
            zoneautre: false,
            zone: 1,
            dataTitle: 'Zone de Navigation Europe<br>(nous couvrons cette zone par défaut)'
        }
    },
    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-10 col-md-6">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">                    
                        <div class="col-10 col-md-5">
                            <div class="form-group row">
                                <div class="col-12 col-sm-8 col-lg-4">
                                    <img class="img-fluid"
                                         src="/templates/medias/templates/amtm/images/zone-de-nav-europe.jpg"
                                         alt="">
                                    <p class="small">Carte non contractuelle fournie à titre indicatif</p>
                                </div>                            
                                <div class="col-12 col-sm-8 col-lg-8 text-left">                                
                                    <p>Europe (Limite)</p>
                                    <ul>
                                        <li>Nord : 60 ° Latitude Nord</li>
                                        <li>Sud : 25 ° Latitude Nord incluant Canaries Madère</li>
                                        <li>Ouest : 30° Longitude Ouest incluant les Açores</li>
                                        <li>Est : 35° Longitude Est sans passage du Bosphore</li>
                                    </ul>
                                </div>
                            </div>
                        </div>                            
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-md-8">
                            <div class="form-group row">                              
                                <div class="col-12">
                                 <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="zoneue"
                                                id="zoneue"
                                                v-model="zoneue"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-7"
                                                :class="{'is-invalid': errors.has('step-7.zoneue')}"
                                                v-validate="{ is: zoneue && zoneant && zoneflu || zoneflu && zoneant || zoneant && zoneautre || zoneflu && zoneautre || zoneue && zoneautre || !zoneue && !zoneant && !zoneflu && !zoneautre }">
                                        <label for="zoneue" class="lh-2 custom-control-label custom-control-stack-label">
                                            Europe     
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-md-8">
                            <div class="form-group row">
                                <div class="col-12">
                                    <p class="text-center"><strong>Si vous naviguez dans une autre zone merci de choisir ci-dessous</strong>
                                    <br><small>(La zone Europe peut être cumulée avec la zone Fluviale ou avec la zone Antilles)</small></p>                                    
                                </div>
                            </div>
                        </div>
                    </div>                                       
                    <div class="row justify-content-center">
                        <div class="col-10 col-sm-8 col-md-5">
                            <div class="form-group row">
                                 <div class="col-4">                                 
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="zoneflu"
                                                id="zoneflu"
                                                v-model="zoneflu"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-7"
                                                :class="{'is-invalid': errors.has('step-7.zoneflu')}"
                                                v-validate="{ is: zoneue && zoneant && zoneflu || zoneflu && zoneant || zoneant && zoneautre || zoneflu && zoneautre || zoneue && zoneautre || !zoneue && !zoneant && !zoneflu && !zoneautre }">
                                        <label for="zoneflu" class="lh-2 custom-control-label custom-control-stack-label">
                                            Fluviale     
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">                         
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="zoneant"
                                                id="zoneant"
                                                v-model="zoneant"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-7"
                                                :class="{'is-invalid': errors.has('step-7.zoneant')}"
                                                v-validate="{ is: zoneue && zoneant && zoneflu || zoneflu && zoneant || zoneant && zoneautre || zoneflu && zoneautre || zoneue && zoneautre || !zoneue && !zoneant && !zoneflu && !zoneautre }">
                                        <label for="zoneant" class="lh-2 custom-control-label custom-control-stack-label">
                                            Antilles     
                                        </label>
                                    </div>
                                </div>                                
                                <div class="col-4">                   
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="zoneautre"
                                                id="zoneautre"
                                                v-model="zoneautre"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-7"
                                                :class="{'is-invalid': errors.has('step-7.zoneautre')}"
                                                v-validate="{ is: zoneue && zoneant && zoneflu || zoneflu && zoneant || zoneant && zoneautre || zoneflu && zoneautre || zoneue && zoneautre || !zoneue && !zoneant && !zoneflu && !zoneautre }">
                                        <label for="zoneautre" class="lh-2 custom-control-label custom-control-stack-label">
                                            Autres     
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                                                          
                    <div class="row justify-content-center" v-if="zoneautre && !zoneue && !zoneflu && !zoneant">
                        <div class="col-12 col-sm-10 col-md-4">
                            <div class="form-group row">
                                 <div class="col-12 mt-3">
                                      <input   type="text"                                                
                                               name="zoneautrelibelle"
                                               id="zoneautrelibelle"
                                               placeholder="Indiquer votre zone de navigation"
                                               class="form-control form-control-lg"
                                               data-vv-scope="step-7"
                                               data-vv-as="zone de navigation"
                                               data-vv-delay="500"
                                               :class="{'is-invalid': errors.has('step-7.zoneautrelibelle')}"
                                               v-validate="zoneautre ? 'required' : ''">
                                      <div v-show="errors.has('step-7.zoneautrelibelle')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-7.zoneautrelibelle') }}
                                      </div>                                           
                                </div>
                            </div>
                        </div>
                    </div>                                                        
                    <div class="row justify-content-center" v-if="zoneue && zoneant && zoneflu || zoneflu && zoneant || zoneant && zoneautre || zoneflu && zoneautre || zoneue && zoneautre || !zoneue && !zoneant && !zoneflu && !zoneautre">
                        <div class="col-12 col-sm-10 col-md-4">
                            <div class="form-group row">
                                 <div class="col-12 mt-3">
                                    <input type="hidden" class="form-control form-control-lg is-invalid"/>
                                    <div class="invalid-feedback" v-if="!zoneue && !zoneant && !zoneflu && !zoneautre">
                                         Vous devez sélectionner une zone de navigation.
                                    </div>
                                    <div class="invalid-feedback" v-else>
                                         Vous ne pouvez pas cumuler ces options.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                              
                    
                    
                </div>`

});



/*
<div class="form-group row">
                                 <div class="col-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationNoSkipper"
                                                id="locationNoSkipper"
                                                v-model="locationNoSkipper"
                                                class="custom-control-input"
                                                data-vv-scope="step-10"
                                                :class="{'is-invalid': errors.has('step-10.locationNoSkipper')}"
                                                v-validate="{ is: locationDetourn && locationSkipper && locationNoSkipper }">
                                        <label for="locationNoSkipper" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>sans skipper</small>
                                        </label>
                                    </div>
                                </div>
                                 <div class="col-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationDetourn"
                                                id="locationDetourn"
                                                v-model="locationDetourn"
                                                class="custom-control-input"
                                                data-vv-scope="step-10"
                                                :class="{'is-invalid': errors.has('step-10.locationDetourn')}"
                                                v-validate="{ is: locationDetourn && locationSkipper && locationNoSkipper }">
                                        <label for="locationDetourn" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>sans skipper<br>avec détournement</small>
                                        </label>
                                    </div>
                                </div>
                                 <div class="col-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationSkipper"
                                                id="locationSkipper"
                                                v-model="locationSkipper"
                                                class="custom-control-input"
                                                data-vv-scope="step-10"
                                                :class="{'is-invalid': errors.has('step-10.locationSkipper')}"
                                                v-validate="{ is: locationDetourn && locationSkipper && locationNoSkipper }">
                                        <label for="locationSkipper" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>avec skipper</small>
                                        </label>
                                        <small @click="showHelp = true"><i class="fa fa-question-circle"></i></small>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationQuai"
                                                id="locationQuai"
                                                v-model="locationQuai"
                                                class="custom-control-input"
                                                data-vv-scope="step-10"
                                                v-validate="''">
                                        <label for="locationQuai" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>à Quai</small>
                                        </label>
                                    </div>
                                </div>
                            </div>






*/