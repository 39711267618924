import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {French} from 'flatpickr/dist/l10n/fr';

Vue.component('step11', {
    data: function () {
        return {
          dataTitle: 'Ajouter des garanties complémentaires',
          titre: 'Nos meilleures options pour vous garantir au mieux !',
          message: 'Nos contrats excluent les bateaux à usage de la Location : Souhaitez-vous garantir ce risque ?',
            infos: 'Pour garantir votre bateau en location (hors pavillon NUC)',
            loa: 'Votre bateau a été acheté en LOA nous vous conseillons par conséquent l’option suivante',
            remorque: 'Si vous avez une remorque veuillez choisir une des options ci-dessous',
            garantie_risque: false,
            ffvoile: false,
            isaf: false,
            licence: null,
            showHelp: false,
            locationNoSkipper: false,
            locationDetourn: false,
            locationSkipper: false,
            locationQuai: false,
            helpLocVisible: false,
            perteFi: false,
            helpPerteVisible: false,
            remorquePlus: false,
            remorqueMoins: false,
            responsabilite: null,
        }
    },
    components: {
        flatPickr
    },
    methods: {
        setHelpPerteVisible: function() {
            this.helpPerteVisible = !this.helpPerteVisible;
        },
    },
    computed: {
        isVoilier: function () {
            return [1, 2, 3].includes(parseInt(this.$root.$refs.step2.$data.boat));
        },
        setIsLoa: function () {
            return true; // [2].indexOf(this.$root.$refs.step5.$data.finance) != -1
        },
        setIs8m: function () {
            return true; // [2].indexOf(this.$root.$refs.step5.$data.finance) != -1
        },
        getCurrentY: function() {
            let d = new Date();
            return parseInt(d.getFullYear());
        },
    },
    inject: ['$validator'],
    template: `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-6">                        
                            <h4 id="titre-tab" class="mt-3 mb-1" v-html="dataTitle"></h4>   
                            <h4 id="titre-tab" class="mt-3 mb-4" v-html="message"></h4>    
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8">
                            <div class="form-group row">
                                 <div class="col-12">                           
                                    <p class="text-center">
                                    <strong v-html="infos"></strong>
                                    <!--<br><small class="text-muted">(Location sans skipper, location avec skipper et location avec détournement ne sont pas cumulables)</small>-->
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div class="row justify-content-center mt-2 mb-2">                      
                        <div class="col-12 col-md-10 col-lg-8">
                            <div class="form-group text-center">
                               <div class="custom-control custom-radio custom-control-inline">
                                  <input    type="radio"
                                            name="garantie_risque"
                                            id="garantie_risqueok"
                                            :value="true"
                                            class="custom-control-input"
                                            v-model="garantie_risque"                                       
                                            data-vv-scope="step-11">
                                  <label class="custom-control-label" for="garantie_risqueok">Oui</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                  <input    type="radio"
                                            name="garantie_risque"
                                            id="garantie_risquenok"
                                            :value="false" 
                                            v-model="garantie_risque"                                        
                                            class="custom-control-input">
                                  <label class="custom-control-label" for="garantie_risquenok">Non</label>
                                </div>
                            </div>
                        </div> 
                  </div>
                    <div class="row justify-content-center" v v-if="garantie_risque">
                        <div class="col-12 col-md-10 col-lg-8">                        
                            <div class="form-group row">                                 
                                 <div class="col-6 col-lg-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationNoSkipper"
                                                id="locationNoSkipper"
                                                v-model="locationNoSkipper"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-11"
                                                :class="{'is-invalid': errors.has('step-11.locationNoSkipper')}"
                                                v-validate="{ is: locationDetourn && locationNoSkipper }">
                                        <label for="locationNoSkipper" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>sans skipper</small>      
                                        </label>
                                    </div>
                                </div>
                                 <div class="col-6 col-lg-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationDetourn"
                                                id="locationDetourn"
                                                v-model="locationDetourn"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-11"
                                                :class="{'is-invalid': errors.has('step-11.locationDetourn')}"
                                                v-validate="{ is: locationDetourn && locationNoSkipper }">
                                        <label for="locationDetourn" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>sans skipper<br>avec détournement</small>     
                                        </label><br>
                                        <small role="button" @click="showHelp = true"><i class="fa-2x fa fa-question-circle"></i></small>
                                    </div>
                                </div>
                                 <div class="col-6 col-lg-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationSkipper"
                                                id="locationSkipper"
                                                v-model="locationSkipper"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-11"
                                                :class="{'is-invalid': errors.has('step-11.locationSkipper')}"
                                                v-validate="{ is: locationDetourn && locationNoSkipper }">
                                        <label for="locationSkipper" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>avec skipper</small>                                                
                                        </label>
                                    </div>
                                </div>                                
                                <div class="col-6 col-lg-3">
                                    <div class="custom-control-stack custom-checkbox">
                                        <input type="checkbox"
                                                name="locationQuai"
                                                id="locationQuai"
                                                v-model="locationQuai"  
                                                class="custom-control-input"                                            
                                                data-vv-scope="step-11"
                                                v-validate="{ is: locationDetourn && locationNoSkipper }">
                                        <label for="locationQuai" class="lh-2 custom-control-label custom-control-stack-label">
                                            Location<br><small>à Quai</small>                                                
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                                                         
                    <div class="row justify-content-center" v-if="locationDetourn && locationNoSkipper">
                        <div class="col-12 col-sm-10 col-md-4">
                            <div class="form-group row">
                                 <div class="col-12 mt-3">
                                    <input type="hidden" class="form-control form-control-lg is-invalid"/>
                                    <div class="invalid-feedback">
                                         Vous ne pouvez pas cumuler ces options.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <transition name="bounce">
                    <!-- Modal content -->
                    <div class="custom-modal" v-if="showHelp">
                    <div class="custom-modal-content">
                      <div class="modal-top">
                        <div class="container-fluid"> 
                        <div class="row justify-content-end">
                            <div class="col-12">
                              <span @click="showHelp = false" class="close">&times;</span>
                            </div>
                        </div>
                        </div>                        
                      </div>
                      <div class="modal-middle"> 
                        <div class="container-fluid">                     
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="media mt-5 mb-3">
                                    <img class="rounded-circle mr-3" width="80"
                                         src="/templates/medias/templates/amtm/images/equipe/couleur/Laure-allochon.png"
                                         alt="">
                                    <div class="media-body text-fourth text-left pl-5"
                                         style="position: relative;">
                                        <div class="vertical-caret"
                                             style="position: absolute;top: 0px;height: 100%;left: 0px;width: 1px;background-color: #f37323;">
                                            <div class="caret"
                                                 style="position: absolute;transform: rotate(-135deg) translateY(50%);top: 50%;height: 20px;left: -16px;width: 20px;background-color: #eff0f4;border-bottom: 1px solid #f37323;border-left: 1px solid #f37323;"></div>
                                        </div>
                                        <p><b>Location sans skipper avec détournement</b><br>est une option qui permet de garantir votre bateau<br>en cas de détournement ou de non-restitution par le locataire.</p>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </transition>
                    
                </div>`

});