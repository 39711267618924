import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {French} from 'flatpickr/dist/l10n/fr';
import vSelect from 'vue-select'

Vue.component('step14', {
    props: ['user'],
    data: function () {
        return {
            url: document.domain,
            dataTitle: 'Votre demande sera traitée dans les meilleurs délais.',
            mention: 'Je confirme accepter les conditions générales d’utilisations du site .....',
            espace: 'Je ne souhaite pas la création d’un espace client me permettant de consulter mes devis et me permettre de souscrire en ligne.',
            souscription: 'Ce qui m’obligera à tout re-saisir pour souscrire.',
            lienMention: 'Mentions légales',
            dateaquisition: '',
            errorLoadinglist: true,
            paysTab: [],
            currentPaysfin: 'FRANCE',
            config: {
                  dateFormat: 'd/m/Y',
                  locale: French, // locale for this instance only
            }
        }
    },
    components: {
        flatPickr,
        vSelect
    },
    methods: {
      setCurrentPaysfin(val) {
          if(val) {
            this.currentPaysfin = val
          }
          else {
            this.currentPaysfin = ''
          }
      },
    },
    mounted() {
      this.$http.get('http://'+ document.domain +'/assurplaisance/get_devis/nationalite_pavillons', {responseType: 'json'}).then(response => {
        this.paysTab = response.data.map(e => e.pays);
        this.errorLoadinglist = false;
      }, response => {
        this.errorLoadinglist = true;
      });
    },
    computed: {
    },
    inject: ['$validator'],
    template: `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-8 mb-3">                        
                            <h4 id="titre-tab" class="mt-3 mb-1" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-lg-8">                                                    
                            <div class="form-group row">
                                <div class="col-12">  
                                 <flat-pickr                                                                     
                                        name="dateaquisition"                                
                                        id="dateaquisition"
                                        placeholder="Merci de préciser la date de votre aquisition"                                                      
                                        class="form-control form-control-lg"
                                        :config="config"           
                                        v-model="dateaquisition"
                                        data-vv-scope="step-14"
                                        data-vv-as="date d'aquisition"
                                        :class="{'is-invalid': errors.has('step-14.dateaquisition')}"
                                        v-validate="''">
                                    </flat-pickr> 
                                    <div v-show="errors.has('step-14.dateaquisition')"
                                        class="invalid-feedback">
                                        {{ errors.first('step-14.dateaquisition') }}
                                    </div>
                                </div>
                            </div>                                                        
                            <div class="form-group row">
                                <div class="col-12 col-lg-6 mb-3 mb-lg-0"> 
                                     <input    type="email"
                                               name="emailfin"
                                               id="emailfin"
                                               placeholder="E-mail"
                                               class="form-control form-control-lg"                                                                                              
                                               data-vv-scope="step-14"
                                               data-vv-as="e-mail"
                                               v-model="user.email"
                                               data-vv-delay="1000"
                                               :class="{'is-invalid': errors.has('step-14.emailfin')}"
                                               v-validate="'required|email'">
                                    <div v-show="errors.has('step-14.emailfin')"
                                         class="invalid-feedback">
                                        {{ errors.first('step-14.emailfin') }}
                                    </div> 
                                </div>
                                <div class="col-12 col-lg-6"> 
                                
                                     <input    type="text"
                                               name="telfin"
                                               id="telfin"
                                               placeholder="Téléphone mobile"
                                               class="form-control form-control-lg"                                                                                              
                                               data-vv-scope="step-14"
                                               data-vv-as="téléphone"
                                               data-vv-delay="1000"
                                               :class="{'is-invalid': errors.has('step-14.telfin')}"
                                               v-validate="{ required: true, regex: /^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$/ }">
                                    <div v-show="errors.has('step-14.telfin')"
                                         class="invalid-feedback">
                                        {{ errors.first('step-14.telfin') }}
                                    </div>  
                                </div>
                            </div>                                                 
                            <div class="form-group row">
                                <div class="col-12 col-lg-6 mb-3 mb-lg-0">                                
                                    <input type="text"
                                           name="prenomfin"
                                           id="prenomfin"
                                           placeholder="Prénom"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="prénom"
                                           data-vv-delay="500"
                                           v-model="user.prenom"
                                           :class="{'is-invalid': errors.has('step-14.prenomfin')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-14.prenomfin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.prenomfin') }}
                                    </div> 
                                </div>
                                <div class="col-12 col-lg-6">                                
                                    <input type="text"
                                           name="nomfin"
                                           id="nomfin"
                                           placeholder="Nom"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="nom"
                                           data-vv-delay="500"
                                           v-model="user.nom"
                                           :class="{'is-invalid': errors.has('step-14.nomfin')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-14.nomfin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.nomfin') }}
                                    </div> 
                                </div>
                            </div>                            
                            <div class="form-group row">
                                <div class="col-12">
                                    <input type="text"
                                           name="raisonfin"
                                           id="raisonfin"
                                           placeholder="Raison sociale si le bateau appartient à une société"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="raison social"
                                           data-vv-delay="500"
                                           :class="{'is-invalid': errors.has('step-14.raisonfin')}"
                                           v-validate="''">
                                    <div v-show="errors.has('step-14.raisonfin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.raisonfin') }}
                                    </div> 
                                </div>
                            </div>                      
                            <div class="form-group row">
                                <div class="col-12">                                 
                                    <input type="text"
                                           name="adressefin"
                                           id="adressefin"
                                           placeholder="Adresse"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="adresse"
                                           data-vv-delay="500"
                                           :class="{'is-invalid': errors.has('step-14.adressefin')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-14.adressefin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.adressefin') }}
                                    </div>  
                                </div>
                            </div>                                                 
                            <div class="form-group row">
                                <div class="col-12 col-lg-6 mb-3 mb-lg-0">                                                                   
                                    <input type="text"
                                           name="postalfin"
                                           id="postalfin"
                                           placeholder="Code postal"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="code postal"
                                           data-vv-delay="500"
                                           :class="{'is-invalid': errors.has('step-14.postalfin')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-14.postalfin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.postalfin') }}
                                    </div>  
                                </div>
                                <div class="col-12 col-lg-6">                                                                    
                                    <input type="text"
                                           name="villefin"
                                           id="villefin"
                                           placeholder="Ville"
                                           class="form-control form-control-lg"
                                           data-vv-scope="step-14"
                                           data-vv-as="ville"
                                           data-vv-delay="500"
                                           :class="{'is-invalid': errors.has('step-14.villefin')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-14.villefin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.villefin') }}
                                    </div>  
                                </div>
                            </div>                                                 
                            <div class="form-group row">
                                <div class="col-12 col-lg-6">  
                                     <v-select
                                        placeholder="Pays"
                                        :options="paysTab"
                                        :class="{'is-invalid': errors.has('step-14.paysfin')}"
                                        v-model="currentPaysfin"                        
                                        input-id="paysfin"
                                        v-on:input="setCurrentPaysfin">                                    
                                        <span slot="no-options">
                                          ...
                                        </span>
                                    </v-select>
                                    <input class="form-control form-control-lg" type="hidden" v-model="currentPaysfin" name="paysfin"
                                    data-vv-scope="step-14"
                                    data-vv-as="pays"
                                    data-vv-delay="500"
                                    :class="{'is-invalid': errors.has('step-14.paysfin')}"
                                    v-validate="'required'"/>
                                    <div v-show="errors.has('step-14.paysfin')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-14.paysfin') }}
                                    </div>
                                </div>
                            </div>                                                                                                          
                            <div class="form-group row">
                                  <div class="col-12">
                                      <p class="text-left"><a target="_blank" :href="'http://'+url+'/informations/mentions'"><small>Consulter les conditions générales d’utilisations du site</small></a></p>
                                  </div>
                            </div>                                                                              
                            <div class="form-group row">
                                  <div class="col-12">
                                        <div class="custom-control custom-checkbox py-2 text-left">                                   
                                            <input  type="checkbox"
                                                    name="confirm"
                                                    id="confirm"      
                                                    class="custom-control-input"                                          
                                                    data-vv-scope="step-14"
                                                    :class="{'is-invalid': errors.has('step-14.confirm')}"
                                                    v-validate="'required'">
                                            <label class="custom-control-label" for="confirm">Je confirme accepter les conditions générales d’utilisations du site.</label>  
                                        </div>
                                  </div>
                            </div>   
                            <div class="form-group row" v-show="errors.has('step-14.confirm')">
                                <div class="col-12">                                    
                                    <input type="hidden" class="form-control form-control-lg is-invalid"/>
                                    <div class="text-left invalid-feedback">
                                        Vous devez accepter les conditions générales d’utilisations du site avant de soumettre le formulaire. 
                                    </div>  
                                </div>
                            </div>
                            <!--                                                                     
                            <div class="form-group row">
                                  <div class="col-12 text-left">
                                        <p>Cela m’obligera à tout re-saisir pour souscrire. <a href="/informations/mentions">Rendez-vous ici pour consulter les mentions légales.</a></p>
                                  </div>
                            </div>
                            -->            
                        </div>
                    </div>                    
                </div>`

});
