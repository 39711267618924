import Vue from 'vue'
import vSelect from 'vue-select'
import 'url-polyfill'
import 'fetch-polyfill'
Vue.component('step3', {
    props: [
      'user'
    ],
    data: function () {
        return {
          hasAnnexe: false,
          maxYear: new Date().getFullYear() + 1,
          minYear: 1800,
          showHelp: false,
          /*
          listConstructeurs: [
            'Commencez à taper votre texte ...'
          ],
          */
          errorLoadinglist: true,
          dataAnnexes: [
            {
              id: this.uniqid(),
              annexeMarque: '',
              annexeAnnee: '',
              annexeMarqueMoteur1: '',
              annexeCv: '',
              annexeAnneeMoteur: '',
              annexeVal: ''

            }
          ],
          // currentConstructor: '',
          currentModele: '',
          listModeles: [
            'Sélectionnez d\'abord un constructeur ...'
          ]
        }
    },
    components: {
      vSelect
    },
    inject: ['$validator'],
    methods: {
      isRequirePuissance: function() {
        return [4, 5, 6, 7].includes(parseInt(this.user.boat)) ? 'required|between:0,9999' : 'decimal|between:0,9999'
      },
      setCurrentModele(val) {
        console.log(val)
        if(val) {
          if(typeof val !== "string") {
            this.currentModele = val.modele
            if(val.longueur) {
              this.user.longBateau = parseFloat(val.longueur).toFixed(2)
            } else {
              this.user.longBateau = ''
            }
          } else {
            this.currentModele = val
          }
        }
        else {
          this.currentModele = ''
        }
      },
      setCurrentConstructeur(val) {
        if(val) {
          this.user.currentConstructor = val.constructeur;
          let vm = this;
          const url = vm.makeUrl( new URL('http://'+ document.domain +'/assurplaisance/modeles/' + this.user.boat), {
            "search": '',
            "constructeur": val.constructeur
          });
          if( val.constructeur ===  undefined ) {
            vm.listModeles = ['Sélectionnez d\'abord un constructeur ...']
          } else {
            fetch(url).then(response => {
              response.json().then(function (json) {
                vm.listModeles = json
              });
            })
          }
        }else{
          this.user.currentConstructor = ''
          this.listModeles = ['Sélectionnez d\'abord un constructeur ...'];
        }
      },
      uniqid: function() {
        var ts=String(new Date().getTime()), i = 0, out = '';
        for(i=0;i<ts.length;i+=2) {
          out+=Number(ts.substr(i, 2)).toString(36);
        }
        return ('d'+out);
      },
      makeUrl: function(url, params){
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        return url;
      },
      addAnnexes: function() {
        if(this.dataAnnexes.length >= 2) {
          return false;
        }
        this.dataAnnexes.push({
          id: this.uniqid(),
          annexeMarque: '',
          annexeAnnee: '',
          annexeMarqueMoteur1: '',
          annexeCv: '',
          annexeAnneeMoteur: '',
          annexeVal: ''

        })
      },
      deleteAnnexes: function(id) {
        this.dataAnnexes = this.dataAnnexes.filter(function(el){
         return el.id != id;
       })
       if(this.dataAnnexes.length === 0) {
         this.hasAnnexe = false
       }
      }
    },
    computed: {
        dataTitle: function() {
            return this.user.prenom + ', merci de sélectionner le constructeur puis le modèle de votre bateau !<br>Sans oublier l\'année de construction et la puissance moteur...';
        },
        showMessageMaxAnnexes() {
          return this.dataAnnexes.length == 2
        },
        hadAnnexe: function() {
            return this.dataAnnexes.length > 0;
        },
    },
    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                     <div class="row justify-content-center">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">                                                              
                                    <v-select
                                    label="constructeur"
                                    placeholder="Liste des constructeurs"
                                    :options="user.listConstructeurs"
                                    :class="{'is-invalid': errors.has('step-3.constructeur')}"
                                    taggable
                                    input-id="constructeur"                                    
                                    v-on:input="setCurrentConstructeur">  
                                        <span slot="no-options">
                                          Aucun constructeur ne correspond ...
                                        </span>    
                                    </v-select>
                                    <input class="form-control form-control-lg" type="hidden" v-model="user.currentConstructor" name="constructeur"
                                    data-vv-scope="step-3"
                                    data-vv-as="constructeur"
                                    data-vv-delay="500"
                                    :class="{'is-invalid': errors.has('step-3.constructeur')}"
                                     v-validate="'required'"/>
                                    <div v-show="errors.has('step-3.constructeur')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.constructeur') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">
                                    <input
                                            type="text"
                                            name="anneeconstr"
                                            id="anneeconstr"
                                            placeholder="Année de construction"
                                            class="form-control form-control-lg"
                                            data-vv-scope="step-3"
                                            data-vv-as="année de construction"
                                            data-vv-delay="500"                                                                                      
                                            :class="{'is-invalid': errors.has('step-3.anneeconstr')}"
                                            v-validate="'digits:4|max_value:'+ maxYear +'|min_value:'+ minYear">                                               
                                    <div v-show="errors.has('step-3.anneeconstr')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.anneeconstr') }}
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">
                                    <v-select
                                      label="modele"
                                      placeholder="Liste des modeles"
                                      :options="listModeles"
                                      :class="{'is-invalid': errors.has('step-3.modele')}"
                                      taggable
                                      input-id="modele"                                    
                                      v-on:input="setCurrentModele">  
                                          <span slot="no-options">
                                            Aucun modele ne correspond ...
                                          </span>    
                                    </v-select>
                                    <input class="form-control form-control-lg" type="hidden" v-model="currentModele" name="modele"
                                      data-vv-scope="step-3"
                                      data-vv-as="modele"
                                      data-vv-delay="500"
                                      :class="{'is-invalid': errors.has('step-3.modele')}"
                                       v-validate="'required'"/>
                                    <div v-show="errors.has('step-3.modele')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.modele') }}
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">                                    
                                    <input  type="text"
                                            name="puissance"
                                            id="puissance"
                                            placeholder="Puissance totale moteurs en CV"
                                            class="form-control form-control-lg"
                                            data-vv-scope="step-3"
                                            data-vv-as="puissance"
                                            data-vv-delay="500"
                                            :class="{'is-invalid': errors.has('step-3.puissance')}"
                                            v-validate="isRequirePuissance()">
                                            
                                    <div v-show="errors.has('step-3.puissance')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.puissance') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="row justify-content-center">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 mt-3">                          
                              <p class="text-left">Longueur du bateau</p>
                          </div>
                     </div>
                    <div class="row justify-content-center">                    
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <input
                                            type="text"
                                            name="longboat"
                                            id="longboat"
                                            v-model="user.longBateau"
                                            placeholder="0.00"
                                            class="form-control form-control-lg"
                                            data-vv-scope="step-3"
                                            data-vv-as="longueur du bateau"
                                            data-vv-delay="500"                                                                                      
                                            :class="{'is-invalid': errors.has('step-3.longboat')}"
                                            v-validate="'required|decimal:2'">                                               
                                    <div v-show="errors.has('step-3.longboat')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.longboat') }}
                                    </div>                                  
                                </div>
                            </div>
                        </div>                            
                    </div>
                    <div class="row justify-content-center mt-5 mb-2">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                            <div class="form-group text-left">                               
                               <label class="control-label mr-5" for="hasnotAnnexe">Avez-vous une Annexe ?</label>
                               <div class="custom-control custom-radio custom-control-inline">
                                  <input    type="radio"
                                            name="hasAnnexe"
                                            id="hasAnnexe"
                                            :value="true"
                                            class="custom-control-input"
                                            v-model="hasAnnexe"
                                            data-vv-scope="step-3">
                                  <label class="custom-control-label" for="hasAnnexe">Oui</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                  <input    type="radio"
                                            name="hasAnnexe"
                                            id="hasnotAnnexe"
                                            :value="false"                                         
                                            class="custom-control-input"
                                            v-model="hasAnnexe">
                                  <label class="custom-control-label" for="hasnotAnnexe">Non</label>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div v-if="hasAnnexe" class="mb-5">
                    <div class="row justify-content-center">
                        <div class=" text-left mb-1" :class="!showMessageMaxAnnexes ? 'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6' : 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3'">
                            <span @click="addAnnexes" class="btn btn-primary btn-sm"><i class="fa fa-plus mr-2" aria-hidden="true"></i> Ajouter une embarcation annexe</span>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-left mb-1" v-if="showMessageMaxAnnexes">
                            <p class="text-muted"><small><i>(Le nombre maximal d'annexe est atteint.)</i></small></p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6" v-if="hadAnnexe">
                            <div class="table-responsive-md">
                            <table class="table table-sm bg-white">
                                <thead class="thead-light">
                                <tr>
                                    <th scope="col">Marque</th>
                                    <th scope="col">Année</th>
                                    <th scope="col">Marque du moteur de l'annexe</th>
                                    <th scope="col">CV</th>
                                    <th scope="col">Année</th> 
                                    <th scope="col">Valeur de l'annexe</th>                                    
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>  
                                <tbody>
                                    <tr v-for="annexe in dataAnnexes">
                                        <td class="bg-secondary"><input style="min-width: 50px" autofocus type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeMarque]')}" v-validate="''" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeMarque]'" :id="'annexeMarque-' + annexe.id" v-model="annexe.annexeMarque"></td>
                                        <td class="bg-secondary"><input style="min-width: 25px" type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeAnnee]')}" v-validate="'digits:4|max_value:'+ maxYear +'|min_value:'+ minYear" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeAnnee]'" :id="'annexeAnnee-' + annexe.id" size="4" v-model="annexe.annexeAnnee"></td>
                                        <td class="bg-secondary"><input style="min-width: 50px" type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeMarqueMoteur1]')}" v-validate="''" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeMarqueMoteur1]'" :id="'annexeMarqueMoteur1-' + annexe.id" v-model="annexe.annexeMarqueMoteur1"></td>
                                        <td class="bg-secondary"><input style="min-width: 25px" type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeCv]')}" v-validate="''" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeCv]'" :id="'annexeCv-' + annexe.id" size="4" v-model="annexe.annexeCv"></td>
                                        <td class="bg-secondary"><input style="min-width: 25px" type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeAnneeMoteur]')}" v-validate="'digits:4|max_value:'+ maxYear +'|min_value:'+ minYear" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeAnneeMoteur]'" :id="'annexeAnneeMoteur-' + annexe.id" size="4" v-model="annexe.annexeAnneeMoteur"></td>
                                        <td class="bg-secondary"><input style="min-width: 50px" type="text" data-vv-scope="step-3" :class="{'is-invalid': errors.has('step-3.annexes['+annexe.id+'][annexeVal]')}" v-validate="'numeric'" class="form-control-plaintext custom-form-control-plaintext bg-white" :name="'annexes['+annexe.id+'][annexeVal]'" :id="'annexeVal-' + annexe.id" size="5" v-model="annexe.annexeVal"></td>
                                        <td class="bg-secondary" style="vertical-align: middle;" @click="deleteAnnexes(annexe.id)">
                                            <span class="text-primary fs-3"><i class="fa fa-times"></i></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                            <div class="form-group row">
                                <div class="input-group col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">                                 
                                    <input
                                            type="text"
                                            name="retourprix"
                                            id="retourprix"
                                            placeholder="Valeur totale declarée"
                                            class="form-control form-control-lg"
                                            data-vv-scope="step-3"
                                            data-vv-as="valeur totale declarée"
                                            data-vv-delay="500"
                                            :class="{'is-invalid': errors.has('step-3.retourprix')}"
                                            v-validate="'required|decimal'">
                                    <div class="input-group-append">
                                        <span class="input-group-text"><small>(en €)</small></span>                                        
                                        <button class="btn btn-link" @click="showHelp = true" type="button"><i class="fa-2x fa fa-question-circle"></i></button>
                                    </div>                                                                        
                                    <div v-show="errors.has('step-3.retourprix')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-3.retourprix') }}
                                    </div>
                                </div>                         
                            </div>
                        </div>
                    </div>
                    <transition name="bounce">
                    <!-- Modal content -->
                    <div class="custom-modal" v-if="showHelp">
                    <div class="custom-modal-content">
                      <div class="modal-top">
                        <div class="container-fluid"> 
                        <div class="row justify-content-end">
                            <div class="col-12">
                              <span @click="showHelp = false" class="close">&times;</span>
                            </div>
                        </div>
                        </div>                        
                      </div>
                      <div class="modal-middle"> 
                        <div class="container-fluid">                     
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <div class="media mt-5 mb-3">
                                    <img class="rounded-circle mr-3" width="80"
                                         src="/templates/medias/templates/amtm/images/equipe/couleur/Laure-allochon.png"
                                         alt="">
                                    <div class="media-body text-fourth text-left pl-5"
                                         style="position: relative;">
                                        <div class="vertical-caret"
                                             style="position: absolute;top: 0px;height: 100%;left: 0px;width: 1px;background-color: #f37323;">
                                            <div class="caret"
                                                 style="position: absolute;transform: rotate(-135deg) translateY(50%);top: 50%;height: 20px;left: -16px;width: 20px;background-color: #eff0f4;border-bottom: 1px solid #f37323;border-left: 1px solid #f37323;"></div>
                                        </div>
                                        <p>La <b>"Valeur Totale"</b> correspond :</p>
                                        <ul>
                                            <li>au corps du navire,</li>
                                            <li>la ou les annexes,</li>
                                            <li>les appareils propulsifs (hélice, embase, transmission),</li>
                                            <li>les accessoires, équipements nécessaires à la navigation,<br>VHF,
                                                GPS, Gréements, accastillages, matériel sécurité...
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </transition>
                    
                </div>`,
});