import Vue from 'vue'

Vue.component('step1', {
    props: [
      'user'
    ],
    data: function() {
        return {
            dataTitle: 'Bonjour, je suis Laure.<br>Je vous propose de vous communiquer un tarif en quelques clics !<br><br>Etes-vous prêts ?'
        }
    },
    inject: ['$validator'],
    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">                    
                        <div class="col-12 col-sm-10 col-md-8 col-lg-4 col-xl-4">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>  
                    </div>            
                    <div class="row justify-content-center">       
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">
                                    <input type="text"
                                           name="prenom"
                                           id="prenom"
                                           placeholder="Prénom"
                                           class="form-control form-control-lg"
                                           @input="user.prenom = $event.target.value.charAt(0).toUpperCase() + $event.target.value.slice(1).toLowerCase()"
                                           data-vv-scope="step-1"
                                           data-vv-as="prénom"
                                           data-vv-delay="500"
                                           v-model="user.prenom"
                                           :class="{'is-invalid': errors.has('step-1.prenom')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-1.prenom')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-1.prenom') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div class="form-group row">
                                <div class="col-12">
                                    <input type="text"
                                           name="nom"
                                           id="nom"
                                           :placeholder="'Nom'.toUpperCase()"
                                           class="form-control form-control-lg"
                                           v-model="user.nom"
                                           @input="user.nom = $event.target.value.toUpperCase()"
                                           data-vv-scope="step-1"
                                           data-vv-as="nom"
                                           data-vv-delay="500"
                                           :class="{'is-invalid': errors.has('step-1.nom')}"
                                           v-validate="'required'">
                                    <div v-show="errors.has('step-1.nom')"
                                         class="invalid-feedback">
                                        {{ errors.first('step-1.nom') }}
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>
                    <div class="row justify-content-center">    
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
                            <div class="form-group row">
                                <div class="col-12">
                                     <input type="email"
                                           name="email"
                                           id="email"
                                           placeholder="E-mail"
                                           class="form-control form-control-lg"
                                           v-model="user.email"
                                           data-vv-scope="step-1"
                                           data-vv-as="e-mail"
                                           data-vv-delay="1000"
                                           :class="{'is-invalid': errors.has('step-1.email')}"
                                           v-validate="'required|email'">
                                    <div v-show="errors.has('step-1.email')"
                                         class="invalid-feedback">
                                        {{ errors.first('step-1.email') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`

});