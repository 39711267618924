import Vue from 'vue'
import vSelect from 'vue-select'
Vue.component('step6', {
    inject: ['$validator'],
    data: function() {
        return {
            membre: false,
            listAssoc: [
              'Commencez à taper le nom de votre association ...',
            ],
            currentAssoc: '',
            errorLoading: false,
            dataTitle: 'Etes-vous membre d’une Association de Plaisanciers ?'
        }
    },
    components: {
      vSelect
    },
    methods: {
      searchAssoc: function(search, loading){
          loading(true);
          fetch('http://'+ document.domain +'/assurplaisance/associations/'+search).then(response => {
            response.json().then(json => {
              if(json.length === 0) {
                this.listAssoc = [
                  'Commencez à taper le nom de votre association ...',
                ]
              } else {
                this.listAssoc = json
              }
              loading(false);
            });
          })
      },
      setCurrentAssoc(val) {
        console.log(val)
        if(val) {
          if(typeof val !== "string") {
            this.currentAssoc = val.libelle
          } else {
            this.currentAssoc = val
          }
        }
        else {
          this.currentAssoc = ''
        }
      },
    },
    mounted: function() {

    },

    template:   `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6 col-xl-4">
                            <div class="form-group row">
                                <div class="col-6">
                                     <div class="custom-control-stack custom-radio">                                     
                                        <input  type="radio"
                                                name="membre"
                                                id="membreok"
                                                :value="true"
                                                class="custom-control-input"
                                                v-model="membre"                                            
                                                data-vv-scope="step-5">
                                        <label class="custom-control-label custom-control-stack-label" for="membreok">
                                            Oui
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="custom-control-stack custom-radio">
                                          <input  type="radio"
                                                    name="membre"
                                                    id="membrenok"
                                                    :value="false"
                                                    class="custom-control-input"
                                                    v-model="membre"                                          
                                                    data-vv-scope="step-5">
                                          <label class="custom-control-label custom-control-stack-label" for="membrenok">
                                                Non
                                          </label>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>             
                    <div class="row justify-content-center" v-if="membre && !errorLoading">
                        <div class="col-12 col-lg-8">
                            <div class="form-group row">
                                <div class="col-12">
                                    <p class="text-center"><strong>Rechercher votre association via votre numéro de département,<br>l'abréviation de celle-ci ou son nom.</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="row justify-content-center" v-if="membre && !errorLoading">
                        <div class="col-12 col-lg-8 col-xl-5">                        
                            <div class="form-group row">
                                <div class="col-12">
                                 <v-select
                                        label="libelle_long"
                                        placeholder="Liste des associations"
                                        input-id="membreassoc"
                                        :options="listAssoc"
                                        taggable
                                        :class="{'is-invalid': errors.has('step-6.membreassoc')}"
                                        @search="searchAssoc"
                                        value.sync="currentAssoc"   
                                        v-on:input="setCurrentAssoc">
                                        <span slot="no-options">
                                          Aucune association ne correspond...
                                        </span>
                                    </v-select>
                                    <input class="form-control form-control-lg" type="hidden" v-model="currentAssoc" name="membreassoc"
                                    data-vv-scope="step-6"
                                    data-vv-as="association"
                                    data-vv-delay="500"
                                    :class="{'is-invalid': errors.has('step-6.membreassoc')}"
                                     v-validate="'required'"/>
                                 
                                    <div v-show="errors.has('step-6.membreassoc')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-6.membreassoc') }}
                                    </div>                    
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>`

});