import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {French} from 'flatpickr/dist/l10n/fr';

Vue.component('step10', {
    data: function () {
        return {
            dataTitle: 'Ajouter des garanties complémentaires',
          titre: 'Nos meilleures options pour vous garantir au mieux !',
          message: 'Notre contrat intègre les régates organisées par  votre club : souhaitez-vous étendre cette garantie ?',
            infos: 'Pour garantir votre bateau en location (hors pavillon NUC)',
            loa: 'Votre bateau a été acheté en LOA nous vous conseillons par conséquent l’option suivante',
            remorque: 'Si vous avez une remorque veuillez choisir une des options ci-dessous',
            ffvoile: false,
            isaf: false,
            licence: null,
            showHelp: false,
            locationNoSkipper: false,
            locationDetourn: false,
            locationSkipper: false,
            locationQuai: false,
            helpLocVisible: false,
            perteFi: false,
            helpPerteVisible: false,
            remorquePlus: false,
            remorqueMoins: false,
            responsabilite: null,
        }
    },
    components: {
        flatPickr
    },
    methods: {
        setHelpPerteVisible: function() {
            this.helpPerteVisible = !this.helpPerteVisible;
        },
    },
    computed: {
        isVoilier: function () {
            return [1, 2, 3].includes(parseInt(this.$root.$refs.step2.$data.boat));
        },
        setIsLoa: function () {
            return true; // [2].indexOf(this.$root.$refs.step5.$data.finance) != -1
        },
        setIs8m: function () {
            return true; // [2].indexOf(this.$root.$refs.step5.$data.finance) != -1
        },
        getCurrentY: function() {
            let d = new Date();
            return parseInt(d.getFullYear());
        },
    },
    inject: ['$validator'],
    template: `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-6">
                            <h4 id="titre-tab" class="mt-3 mb-1" v-html="dataTitle"></h4>   
                            <h4 id="titre-tab" class="mt-3 mb-4" v-html="message"></h4>    
                        </div>
                    </div>
                    <div class="row justify-content-center" v-if="!isVoilier">
                        <div class="col-12 col-lg-7 mb-5">
                            <div class="form-group row">
                                <div class="col-12 col-lg-6 mb-0 mb-lg-3">
                                    <div class="custom-control custom-checkbox py-2 text-left">                                   
                                        <input  type="checkbox"
                                                name="ffvoile"
                                                id="ffvoile"                                                
                                                v-model="ffvoile"
                                                class="custom-control-input"                                          
                                                data-vv-scope="step-10"
                                                v-validate="''">
                                        <label class="custom-control-label" for="ffvoile">à toutes les régates inscrites aux calendriers de la FFVoile *</label>  
                                    </div>
                                    <div class="custom-control custom-checkbox py-2 text-left">                                   
                                       <input  type="checkbox"
                                                name="isaf"
                                                id="isaf"                                                
                                                v-model="isaf"
                                                class="custom-control-input"                                          
                                                data-vv-scope="step-10"
                                                v-validate="''">
                                        <label class="custom-control-label" for="isaf">à tous types de régates organisées selon les règles de l’ISAF *</label>  
                                    </div>
                                </div>                                
                                </div>
                                <div class="form-group row">
                                <div class="col-12" v-if="!isVoilier && (isaf || ffvoile)">
                                    <p class="text-center lh-2"><strong>Indiqué votre N° de licence FFVoile valide</strong><br><small class="text-muted">(Un seul pour les deux types de compétition)</small></p>
                                    <input  type="text"
                                            name="licence"
                                            id="licence"
                                            placeholder="Votre N° de licence"
                                            class="form-control form-control-lg"
                                            data-vv-scope="step-10"
                                            data-vv-as="n° de licence"
                                            data-vv-delay="500"
                                            :class="{'is-invalid': errors.has('step-10.licence')}"
                                            v-validate="'required'">                           
                                    <div v-show="errors.has('step-10.licence')"
                                         class="invalid-feedback">
                                         {{ errors.first('step-10.licence') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8 mb-5">
                        <p class="text-muted">*Attention : Il est à noter que certaines régates peuvent-être exclues de nos conditions.</p>
                        </div>
                    </div>
                </div>`

});