import Vue from 'vue'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {French} from 'flatpickr/dist/l10n/fr';
import vSelect from 'vue-select'

Vue.component('step8', {
  props: [
    'user'
  ],
  data: function () {
    return {
      hasResi: null,
      showHelp: false,
      listAssur: [],
      listMotif: [],
      errorLoadinglist: true,
      currentAssur: '',
      currentMotif: '',
      dateecheance: null,
      dataTitle: 'Avez-vous déjà été assuré pour un bateau ?',
      config: {
        dateFormat: 'd/m/Y',
        // maxDate: "today",
        locale: French, // locale for this instance only
      },
    }
  },
  methods: {
    setCurrentAssur(val) {
      if (val) {
        this.currentAssur = val
      }
      else {
        this.currentAssur = ''
      }
    },
    setCurrentMotif(val) {
      if (val) {
        this.currentMotif = val
      }
      else {
        this.currentMotif = ''
      }
    }
  },
  mounted() {
    this.$http.get('http://' + document.domain + '/assurplaisance/get_devis/assureurs', {responseType: 'json'}).then(response => {
      this.listAssur = response.data.map(e => e.assureur);
      this.errorLoadinglist = false;
    }, response => {
      this.errorLoadinglist = true;
    })
    this.$http.get('http://' + document.domain + '/assurplaisance/resiliations', {responseType: 'json'}).then(response => {
      this.listMotif = response.data.map(e => e.resiliation);
      this.errorLoadinglist = false;
    }, response => {
      this.errorLoadinglist = true;
    })
  },
  components: {
    vSelect,
    flatPickr
  },
  inject: ['$validator'],
  template: `<div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-6">                        
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-5 col-lg-3">
                            <div class="form-group row">
                                 <div class="col-6">
                                     <div class="custom-control-stack custom-radio">                                     
                                        <input  type="radio"
                                                name="dejaassur"
                                                id="dejaassurok"
                                                :value="true"
                                                class="custom-control-input"
                                                v-model="user.dejaassur"                                            
                                                data-vv-scope="step-8"
                                                :class="{'is-invalid': errors.has('step-8.dejaassur')}"
                                                v-validate="'required'">
                                        <label class="custom-control-label custom-control-stack-label" for="dejaassurok">
                                            Oui
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="custom-control-stack custom-radio">
                                         <input type="radio"
                                                name="dejaassur"
                                                id="dejaassurnok"
                                                :value="false"
                                                class="custom-control-input"
                                                v-model="user.dejaassur"                                          
                                                data-vv-scope="step-8"
                                                :class="{'is-invalid': errors.has('step-8.dejaassur')}">
                                         <label class="custom-control-label custom-control-stack-label" for="dejaassurnok">
                                              Non
                                         </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="user.dejaassur">
                      <div class="row justify-content-center">
                          <div class="col-12 col-md-10 col-lg-8 mt-3">                          
                              <div class="form-group row">
                                  <div class="input-group col-12 col-lg-6 mb-3 mb-lg-0">
                                      <v-select
                                          label="assureur"
                                          placeholder="Liste des assureurs"
                                          :options="listAssur"
                                          :class="{'is-invalid': errors.has('step-8.assur')}"       
                                          value.sync="currentAssur"
                                          taggable                            
                                          input-id="assur"
                                          v-on:input="setCurrentAssur">                                    
                                              <span slot="no-options">
                                                Sélectionnez un assureur ...
                                              </span>
                                          </v-select>
                                          <input class="form-control form-control-lg" type="hidden" v-model="currentAssur" name="assur"
                                          data-vv-scope="step-8"
                                          data-vv-as="assureur"
                                          data-vv-delay="500"
                                          :class="{'is-invalid': errors.has('step-8.assur')}"
                                           v-validate="'required'"/>
                                      <div class="input-group-append">                                                                                
                                          <button class="btn btn-link" @click="showHelp = true" type="button"><i class="fa-2x fa fa-question-circle"></i></button>
                                      </div> 
                                      <div v-show="errors.has('step-8.assur')"
                                               class="invalid-feedback">
                                               {{ errors.first('step-8.assur') }}
                                      </div>
                                  </div>
                                  <transition name="bounce">
                                  <!-- Modal content -->
                                  <div class="custom-modal" v-if="showHelp">
                                  <div class="custom-modal-content">
                                    <div class="modal-top">
                                      <div class="container-fluid"> 
                                      <div class="row justify-content-end">
                                          <div class="col-12">
                                            <span @click="showHelp = false" class="close">&times;</span>
                                          </div>
                                      </div>
                                      </div>                        
                                    </div>
                                    <div class="modal-middle"> 
                                      <div class="container-fluid">                     
                                      <div class="row justify-content-center">
                                          <div class="col-12">
                                              <div class="media mt-5 mb-3">
                                                  <img class="rounded-circle mr-3" width="80"
                                                       src="/templates/medias/templates/amtm/images/equipe/couleur/Laure-allochon.png"
                                                       alt="">
                                                  <div class="media-body text-fourth text-left pl-5"
                                                       style="position: relative;">
                                                      <div class="vertical-caret"
                                                           style="position: absolute;top: 0px;height: 100%;left: 0px;width: 1px;background-color: #f37323;">
                                                          <div class="caret"
                                                               style="position: absolute;transform: rotate(-135deg) translateY(50%);top: 50%;height: 20px;left: -16px;width: 20px;background-color: #eff0f4;border-bottom: 1px solid #f37323;border-left: 1px solid #f37323;"></div>
                                                      </div>
                                                      <p>Nous pouvons vous aider dans le processus<br>de résiliation auprès de votre assureur !</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                  </transition>
                                  <div class="input-group col-12 col-lg-6">                                    
                                        <flat-pickr                                                                     
                                            name="dateecheance"                                
                                            id="dateecheance"
                                            placeholder="Date d'échéance"                                                      
                                            class="form-control form-control-lg"
                                            :config="config"           
                                            v-model="dateecheance"
                                            data-vv-scope="step-8"
                                            data-vv-as="date d'échéance"
                                            :class="{'is-invalid': errors.has('step-8.dateecheance')}"
                                            v-validate="''">
                                        </flat-pickr> 
                                        <div v-show="errors.has('step-8.dateecheance')"
                                            class="invalid-feedback">
                                            {{ errors.first('step-8.dateecheance') }}
                                        </div>
                                    </div>
                                    
                              </div>
                          </div>
                      </div>                    
                      <div class="row justify-content-center">
                          <div class="col-8">
                              <div class="form-group row">
                                  <div class="col-12 mt-3">
                                      <p class="text-center"><strong>Avez-vous été résilié par votre précédent assureur ?</strong></p>
                                  </div>
                              </div>
                          </div>
                      </div> 
                      <div class="row justify-content-center">
                          <div class="col-12 col-lg-3">
                              <div class="form-group row">
                                   <div class="col-6">
                                       <div class="custom-control-stack custom-radio">                                     
                                          <input  type="radio"
                                                  name="hasResi"
                                                  id="hasResiok"
                                                  :value="true"
                                                  class="custom-control-input"
                                                  v-model="hasResi"                                            
                                                  data-vv-scope="step-8"
                                                  :class="{'is-invalid': errors.has('step-8.hasResi')}"
                                                  v-validate="'required'">
                                          <label class="custom-control-label custom-control-stack-label" for="hasResiok">
                                              Oui
                                          </label>
                                      </div>
                                  </div>
                                  <div class="col-6">
                                      <div class="custom-control-stack custom-radio">
                                           <input type="radio"
                                                  name="hasResi"
                                                  id="hasResinok"
                                                  :value="false"
                                                  class="custom-control-input"
                                                  v-model="hasResi"                                          
                                                  data-vv-scope="step-8"
                                                  :class="{'is-invalid': errors.has('step-8.hasResi')}">
                                           <label class="custom-control-label custom-control-stack-label" for="hasResinok">
                                                Non
                                           </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                      <div class="row justify-content-center" v-if="errors.has('step-8.hasResi')">
                          <div class="col-12 col-lg-5">                          
                              <div class="form-group row">
                                   <div class="col-12">                                    
                                      <input type="hidden" class="form-control form-control-lg is-invalid"/>
                                          <div class="invalid-feedback">
                                               Merci d'indiquer une réponse à la question ci-dessus.
                                          </div>  
                                      </div>  
                                  </div>
                              </div>
                          </div>
                      </div>                                                        
                      <div class="row justify-content-center" v-if="hasResi">                          
                          <div class="col-12 col-lg-8 col-xl-4">
                              <div class="form-group row">
                                   <div class="col-12 mt-3">
                                   <v-select
                                          placeholder="Motif de la résiliation"
                                          :options="listMotif"
                                          :class="{'is-invalid': errors.has('step-8.motifresi')}"       
                                          value.sync="currentMotif"                            
                                          input-id="motifresi"
                                          v-on:input="setCurrentMotif">                                    
                                              <span slot="no-options">
                                                Sélectionnez un motif de résiliation ...
                                              </span>
                                          </v-select>
                                          <input class="form-control form-control-lg" type="hidden" v-model="currentMotif" name="motifresi"
                                          data-vv-scope="step-8"
                                          data-vv-as="motif de la résiliation"
                                          data-vv-delay="500"
                                          :class="{'is-invalid': errors.has('step-8.motifresi')}"
                                           v-validate="'required'"/>                                       
                                      <div v-show="errors.has('step-8.motifresi')"
                                           class="invalid-feedback">
                                           {{ errors.first('step-8.motifresi') }}
                                      </div>                                   
                                  </div>
                              </div>
                          </div>
                      </div>  
                    </div>                 
                </div>`

});