import Vue from 'vue'
import Sinistres from './../components/sinistres.vue'

Vue.component('step9', {
      props: [
        'user'
      ],
    data: function () {
        return {
            hadSinistres: null,
            nbSinistres: 1,
            dataTitle: 'Avez-vous eu des Sinistres sur les 36 derniers mois ?'
        }
    },
    components: {
        Sinistres
    },
    computed: {
        tabSinistres: function() {
            if (!this.nbSinistres || parseInt(this.nbSinistres) === 3) return 0;
            return parseInt(this.nbSinistres);
        }
    },
    inject: ['$validator'],
template: `     <div class="tab col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 col-lg-6">
                            <h4 id="titre-tab" class="mt-3 mb-5" v-html="dataTitle"></h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-5 col-lg-3">
                            <div class="form-group row">
                                 <div class="col-6">
                                     <div class="custom-control-stack custom-radio">                                     
                                        <input  type="radio"
                                                name="hadSinistres"
                                                id="hadSinistresok"
                                                :value="true"
                                                class="custom-control-input"
                                                v-model="hadSinistres"                                            
                                                data-vv-scope="step-9"
                                                :class="{'is-invalid': errors.has('step-9.hadSinistres')}"
                                                v-validate="'required'">
                                        <label class="custom-control-label custom-control-stack-label" for="hadSinistresok">
                                            Oui
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="custom-control-stack custom-radio">
                                         <input type="radio"
                                                name="hadSinistres"
                                                id="hadSinistresnok"
                                                :value="false"
                                                class="custom-control-input"
                                                v-model="hadSinistres"                                          
                                                data-vv-scope="step-9"
                                                :class="{'is-invalid': errors.has('step-9.hadSinistres')}">
                                         <label class="custom-control-label custom-control-stack-label" for="hadSinistresnok">
                                              Non
                                         </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row justify-content-center" v-show="errors.has('step-9.hadSinistres')">
                        <div class="col-12 col-md-8 col-lg-5">                        
                            <div class="form-group row">
                                <div class="col-12">                                    
                                    <input type="hidden" class="form-control form-control-lg is-invalid"/>
                                    <div class="invalid-feedback">
                                    Merci d'indiquer une réponse à la question ci-dessus.
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div v-if="hadSinistres">                           
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-5 col-lg-3">                            
                                <div class="form-group row">
                                    <div class="col-12 mt-3">
                                        <p class="text-center"><strong>Combien ?</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-10 col-lg-5">
                                <div class="form-group row">
                                     <div class="col-12">
                                       <select  name="nbsinistres"
                                                id="nbsinistres"
                                                v-model="nbSinistres"
                                                class="form-control form-control-lg"
                                                data-vv-scope="step-8">                                        
                                                <option :value="1">
                                                    1
                                                </option>
                                                <option :value="2">
                                                    2
                                                </option>
                                                <option :value="3">
                                                    ou plus ...
                                                </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <sinistres v-if="tabSinistres != 0"
                                 :index="index"
                                 :sinistre="sinistre"
                                 :key="sinistre.id"
                                 v-for="(sinistre, index) in tabSinistres">
                        </sinistres>
                    </div>                
                </div>`

});