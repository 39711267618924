<template>
    <div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8">
            <div class="form-group row">
                <div class="col-12 mt-3">
                    <p class="text-center"><strong>Sinistre N°{{ index + 1 }}</strong></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-2 mb-2">
        <div class="col-12 col-md-10 col-lg-8">
            <div class="form-group text-left">
                <label class="control-label mr-5" :for="'responsable' + (index + 1)">Etiez-vous responsable ?</label>
                <div class="custom-control custom-radio custom-control-inline">
                    <input    type="radio"
                              :name="'responsable' + (index + 1)"
                              :id="'responsable' + (index + 1) + 'ok'"
                              :value="true"
                              class="custom-control-input"
                              v-model="responsable"
                              data-vv-scope="step-9">
                        <label class="custom-control-label" :for="'responsable' + (index + 1) + 'ok'">
                              Oui
                        </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input    type="radio"
                              :name="'responsable' + (index + 1)"
                              :id="'responsable' + (index + 1) + 'nok'"
                              :value="false"
                              class="custom-control-input"
                              v-model="responsable">
                        <label class="custom-control-label" :for="'responsable' + (index + 1) + 'nok'">
                            Non
                        </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8">
            <div class="form-group row">
                <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                    <div class="input-group">
                    <input type="text"
                           :name="'montant' + (index + 1)"
                           :id="'montant' + (index + 1)"
                           class="form-control form-control-lg"
                           aria-label="Montant du sinistre en €"
                           placeholder="Montant en €"
                           data-vv-scope="step-9"
                           data-vv-as="montant du sinistre"
                           data-vv-delay="500"
                           :class="{'is-invalid': errors.has('step-9.montant' + (index + 1))}"
                           v-validate="'numeric|min_value:0'">
                    <div class="input-group-append">
                        <span class="input-group-text"><small>(en €)</small></span>
                    </div>
                    <div v-show="errors.has('step-9.montant' + (index + 1))"
                         class="invalid-feedback">
                        {{ errors.first('step-9.montant' + (index + 1)) }}
                    </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <textarea :name="'description' + (index + 1)"
                              :id="'description' + (index + 1)"
                              class="form-control"
                              aria-label="Description du sinistre"
                              placeholder="La description du sinistre ..."
                              data-vv-scope="step-9"
                              data-vv-as="description du sinistre"
                              data-vv-delay="500"
                              :class="{'is-invalid': errors.has('step-9.description' + (index + 1))}"
                              v-validate="'max:250'"></textarea>
                    <div v-show="errors.has('step-9.description' + (index + 1))"
                         class="invalid-feedback">
                        {{ errors.first('step-9.description' + (index + 1)) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        inject: ['$validator'],
        props: ['index', 'sinistre'],
        data () {
            return {
                responsable: null,
            }
        }
    }

</script>

<style scoped>


</style>